import isTouchDevice from "../../../utils/isTouchDevice"
import trash_icon from "../../../assets/layouts/trash.png"
import rotate_icon from "../../../assets/layouts/rotate.png"

export default function GateAction(props) {
    if(props.show){
        const item = props.gate

        let left = props.currentEvent.clientX
        let top = props.currentEvent.clientY

        if(isTouchDevice()){
            top = top + 45
            left = left - 35
        }else{
            if(item.x2 < item.y2){
                top = top - 70
                left = left - 28
            }else{
                top = top - 10
                left = left + 30
            }
        }
        

        const inlineStyles = {
            "left": left,
            "top": top,
            
        }

        const imageInlineStyles = {
            "boxShadow": '0px 0px 10px -2px rgb(0,0,0,0.7)'
        }
        
        return <div className="absolute z-50 flex" style={inlineStyles}>
            <button type="button" className="text-2xl mx-2 w-6 h-6 overflow-hidden rounded-full outline-none" onClick={props.handleRemoveGate} style={imageInlineStyles}>
                <img src={trash_icon} className="hover:opacity-80 overflow-hidden rounded-full" />
            </button>
            <button type="button" className="text-2xl mx-2 w-6 h-6 overflow-hidden rounded-full outline-none" onClick={props.handleFlipGate} style={imageInlineStyles}>
                <img src={rotate_icon} className="hover:opacity-80 overflow-hidden rounded-full" />
            </button>
        </div>
    }
    return true
}