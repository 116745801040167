import post_icon_w from "../../../assets/layouts/post_icon_w.png"
import gate_icon_w from "../../../assets/layouts/gate_icon_w.png"
import post_icon_g from "../../../assets/layouts/post_icon_g.png"
import gate_icon_g from "../../../assets/layouts/gate_icon_g.png"

export default function ActionButton(props) {
    
    return <div className="w-full lg:w-72 h-12 bg-secondary rounded-full flex justify-around items-center mr-5 mt-2">
    <button type="button" className={`relative w-full h-full rounded-full cursor-pointer -mr-3 p-4 outline-none ${props.action === 'Post' ? 'bg-primary text-white z-20 shadow-right' : 'z-10 text-secondary-dark'}`} onClick={() => props.handleAction("Post")}>
      <span className="inset-0 absolute flex items-center justify-center mr-8 \">
        <img src={props.action === 'Post' ? post_icon_w : post_icon_g } width="32px" className="mr-4" alt="post icon"/>
        Post
      </span>
    </button>
    <button type="button" className={`relative w-full h-full rounded-full cursor-pointer -ml-3 p-4 outline-none ${props.action === 'Gate' ? 'bg-primary text-white z-20 shadow-left' : 'z-10 text-secondary-dark'}`} onClick={() => props.handleAction("Gate")}>
      <span className="inset-0 absolute flex items-center justify-center ml-8">
        Gate
        <img src={props.action === 'Gate' ? gate_icon_w : gate_icon_g } width="32px" className="ml-4" alt="gate icon"/>
      </span>
    </button>
  </div>

}