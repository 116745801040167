import Paragraph from "../../texts/Paragraph";
import PrimarySubmitButton from "../../buttons/PrimarySubmitButton";

export default function Modal(props) {

    return <div className={`modal ${!props.show ? 'hidden' : ''} h-screen w-screen fixed left-0 top-0 bg-white z-50 overflow-x-hidden bg-overlay-dark`}>
        <div className="diaglog relative h-auto w-full md:w-2/5 m-auto mt-0 md:rounded-md bg-white px-4 md:px-8 py-4 md:py-6">
            <button type="button" onClick={props.closeModal}
             title="Close"
             className="absolute right-8 bg-transparent font-bold text-lg opacity-60 hover:opacity-100">
                &#10005;
            </button>
            <h3 className="text-xl font-bold text-center">{ props.title }</h3>
            <div className="body mt-4">
                { props.body }
            </div>
        </div>
    </div>
    ;
    
}

Modal.defaultProps = {
    show: false,
}