import React, { useLayoutEffect, useState } from "react"
import rough from 'roughjs/bundled/rough.esm'

import WebFont from 'webfontloader';

import { ProductService } from "./services/ProductService"

import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

import isTouchDevice from "./utils/isTouchDevice"
import dynamicPostIcons from "./lib/dynamicPostIcons"

import PageHeading from "./components/texts/PageHeading"
import Paragraph from "./components/texts/Paragraph"
import SmallTextField from "./components/fields/SmallTextField"
import SmallButton from './components/buttons/SmallButton'
import ActionButton from "./components/buttons/RadioButton"
import PrimaryButton from "./components/buttons/PrimaryButton"
import OutlineButton from "./components/buttons/OutlineButton"
import ItemList from "./components/layout/ItemList"
import Canvas from "./components/layout/Canvas"
import GateAction from "./components/layout/GateAction"
import Modal from "./components/layout/Modal"

import post_y_icon from "./assets/layouts/post_y_icon.png"
import post_x_icon from "./assets/layouts/post_x_icon.png"
import post_z_lr_icon from "./assets/layouts/post_z_lr_icon.png"
import post_z_rl_icon from "./assets/layouts/post_z_rl_icon.png"
import post_y_light_icon from "./assets/layouts/post_y_light_icon.png"
import post_x_light_icon from "./assets/layouts/post_x_light_icon.png"
import post_z_lr_light_icon from "./assets/layouts/post_z_lr_light_icon.png"
import post_z_rl_light_icon from "./assets/layouts/post_z_rl_light_icon.png"
import gate_x from "./assets/layouts/gate_x.png"
import gate_y from "./assets/layouts/gate_y.png"
import gate_x_light from "./assets/layouts/gate_x_light.png"
import gate_y_light from "./assets/layouts/gate_y_light.png"
import gate_x_f from "./assets/layouts/gate_x_f.png"
import gate_y_f from "./assets/layouts/gate_y_f.png"
import gate_x_fa from "./assets/layouts/gate_x_f_a.png"
import gate_x_fb from "./assets/layouts/gate_x_f_b.png"
import gate_y_fa from "./assets/layouts/gate_y_f_a.png"
import gate_y_fb from "./assets/layouts/gate_y_f_b.png"


import minus from "./assets/layouts/minus.png"
import plus from "./assets/layouts/plus.png"
import minus_2 from "./assets/layouts/minus_2.png"
import plus_2 from "./assets/layouts/plus_2.png"


const PRODUCT_FILTER = {
  'topBottomRails': {
    custom: '3MTBRSC',
    white: '3MTBRSW',
    satinBlack: '3MTBRSSB',
    surfmist: '3MTBRSS',
    monument: '3MTBRSM',
  },
  'posts': {
    custom: 'PKC',
    white: 'PKW',
    satinBlack: 'PKSB',
    surfmist: 'PKS',
    monument: 'PKM',
  },
  'glassSet': 'GLASS SET',
  'straightBracket': {
    custom: 'SBKC',
    white: 'SBKW',
    satinBlack: 'SBKSB',
    surfmist: 'SBKS',
    monument: 'SBKM',
  },
  'adjustableAngleBracket': {
    custom: 'ABKC',
    white: 'ABKW',
    satinBlack: 'ABKSB',
    surfmist: 'ABKS',
    monument: 'ABKM',
  },
  'degreeBracket': 'CBKC'
}

const generator = rough.generator()

const api = new WooCommerceRestApi({
  url: process.env.REACT_APP_WC_URL,
  consumerKey: process.env.REACT_APP_WC_CONSUMER_KEY,
  consumerSecret: process.env.REACT_APP_WC_SECRET_KEY,
  version: "wc/v3"
})

function createElement(x1, y1, x2, y2, line = '', group, stroke, color = '', measurement = '') {
  color = color !== '' ? "#03658c" : "rgb(164, 195, 208, 0.75)"
  const roughElement = generator.line(x1, y1, x2, y2, { strokeWidth: stroke, roughness: 0.1, fillLineDash: [5, 15], stroke: color })
  return { x1, y1, x2, y2, measurement, roughElement, line, group }
}

function createPostElement(postId, image, x1, y1, x2, y2, line = '', image_type = '', direction = '', color='blue') {
  return { postId, image, x1, y1, x2, y2, line, image_type, direction, color }
}

function calculateColumns(canvas, rows) {
  // Calculate the width of each cell
  var cellWidth = canvas.height / rows;

  // Calculate the number of columns needed to fit the canvas exactly
  var columns = Math.ceil(canvas.width / cellWidth);

  return columns;
}

function drawBoard(canvas, columns) {
  var ctx = canvas.getContext("2d");

  // Calculate the width of each cell
  var cellWidth = canvas.width / columns;

  // Loop through each row
  for (var i = 0; i < canvas.height / cellWidth; ++i) {
    // Loop through each column in the current row
    for (var j = 0; j < columns; ++j) {
      // Draw a rectangle (cell) at the specified position
      ctx.rect(j * cellWidth, i * cellWidth, cellWidth, cellWidth);
    }
  }

  // Set the stroke color for the grid lines
  ctx.strokeStyle = "#dfdfdf";

  // Draw the grid lines
  ctx.stroke();
}

function canvasDimension(canvasContainer) {

  const computedStyle = getComputedStyle(canvasContainer)

  let width = canvasContainer.clientWidth;
  let height = canvasContainer.clientHeight;

  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)

  return { width, height }
}

const nearPoint = (x, y, x1, y1) => {
  return Math.abs(x - x1) < 1 && Math.abs(y - y1) < 1 ? "ok" : null;
};

const positionWithinElement = (x, y, baseBox, element) => {

  const { x1, x2, y1, y2 } = element

  const near = nearPoint(x, y, x1, y1)

  const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null
  return near || inside

}

const getElementAtPosition = (x, y, baseBox, elements, type) => {
  return elements
    .map(element => ({ ...element, position: positionWithinElement(x, y, baseBox, element) }))
    .find(element => element.position !== null)
}

function inLine(point, direction, canvas, cell, imageSize) {

  const length = canvas.width / cell

  const offset = 0
  const p2 = (point + length - offset) - ((point + length - offset) % length)

  imageSize = imageSize / 2

  if (point - offset < p2 - (length / 2)) {
    return p2 - (length) - imageSize
  } else {
    return p2 - imageSize
  }
}

function isPointWithinLine(x1, y1, x2, y2, mouseX, mouseY, strokeWidth) {
  // Calculate the distance from the point to both endpoints of the line segment
  var distanceFromP1 = Math.sqrt((mouseX - x1) ** 2 + (mouseY - y1) ** 2);
  var distanceFromP2 = Math.sqrt((mouseX - x2) ** 2 + (mouseY - y2) ** 2);

  // Calculate the length of the line segment
  var lineLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  // Check if the sum of the distances is approximately equal to the length of the line segment
  return Math.abs(distanceFromP1 + distanceFromP2 - lineLength) < strokeWidth;
}

function isStraightLine(clickedLine) {
  // Check if the line is horizontal (x1 and x2 are the same)
  if (ceil(clickedLine.x1) === ceil(clickedLine.x2)) {
    return true;
  }
  
  // Check if the line is vertical (y1 and y2 are the same)
  if (ceil(clickedLine.y1) === ceil(clickedLine.y2)) {
    return true;
  }
  
  // If neither horizontal nor vertical, it's not a straight line
  return false;
}

function findLine(clientX, clientY, elements, stokeWidth) {
  let el = []

  elements.forEach((item, index) => {

    if (isPointWithinLine(item.x1, item.y1, item.x2, item.y2, clientX, clientY, stokeWidth)) {
      el.push(item)
    }
  })

  return el
}

function findInPost(clientX, clientY, postElements, elements, postImageSize) {
  const post = postElements.filter(item => clientX >= item.x1 && clientX <= item.x1 + item.x2 && clientY >= item.y1 && clientY <= item.y1 + item.y2)
  
  if(post.length > 0){
    const line = elements.find(item => (ceil(item.x1 - postImageSize / 2) === ceil(post[0].x1) && ceil(item.y1 - postImageSize / 2) === ceil(post[0].y1)) || (ceil(item.x2 - postImageSize / 2) === ceil(post[0].x1) && ceil(item.y2 - postImageSize / 2) === ceil(post[0].y1)))
    if(line){
      return post
    }
  }
  return []
}

function getDirection(startX, startY, endX, endY, con) {
  let dir = ""
  startX = ceil(startX)
  startY = ceil(startY)
  endX = ceil(endX)
  endY = ceil(endY)
  const cx1 = ceil(con.x1)
  const cy1 = ceil(con.y1)
  const cx2 = ceil(con.x2)
  const cy2 = ceil(con.y2)
  if (startX === endX && cx1 === cx2) {
    dir = 'Y'
  } else if (startY === endY && cy1 === cy2) {
    dir = 'X'
  } else if (cx1 < cx2) {
    if ((startX === cx1 && startY < endY) || (startX === cx2 && startY > endY)) {
      dir = "LR"
    } else if ((startX === cx1 && startY > endY) || (startX === cx2 && startY < endY)) {
      dir = "RL"
    }
  } else if (cx1 > cx2) {
    if ((startX === cx2 && startY < endY) || (startX === cx1 && startY > endY)) {
      dir = "LR"
    } else if ((startX === cx2 && startY > endY) || (startX === cx1 && startY < endY)) {
      dir = "RL"
    }
  } else if (cy1 < cy2) {
    if ((startY === cy1 && startX < endX && cy1 < cy2) || (startY === cy2 && startX > endX && cy1 < cy2)) {
      dir = "LR"
    } else if ((startY === cy2 && startX < endX && cy1 < cy2) || (startY === cy1 && startX > endX && cy1 < cy2)) {
      dir = "RL"
    }
  } else if (cy1 > cy2) {
    if ((startY === cy2 && startX < endX && cy1 > cy2) || (startY === cy1 && startX > endX && cy1 > cy2)) {
      dir = "LR"
    } else if ((startY === cy1 && startX < endX && cy1 > cy2) || (startY === cy2 && startX > endX && cy1 > cy2)) {
      dir = "RL"
    }
  }

  return dir
}

function roundOff(value) {
  return Math.round(value * 100) / 100
}

function ceil(value) {
  return Math.ceil(value)
}

function getMousePos(canvas, evt) {
  var rect = canvas.getBoundingClientRect(), // abs. size of element
  scaleX = canvas.width / rect.width,    // relationship bitmap vs. element for X
  scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y
  let cX = evt.clientX
  let cY = evt.clientY

  return {
    x: (cX - rect.left) * scaleX,   // scale mouse coordinates after they have
    y: (cY - rect.top) * scaleY     // been adjusted to be relative to element
  }
}
// OLD CORNER POST COUNTER
// function cornerPostCounter(postElements) {
//   const posts = postElements.filter(item => item.direction === 'corner')
//   return posts.length
// }

function cornerPostCounter(lines) {
  let uniqueCorners = new Set();

  // Function to add unique corner points
  function addUniqueCorner(x, y) {
      uniqueCorners.add(x + ',' + y);
  }

  // Iterate through all pairs of lines
  for (let i = 0; i < lines.length; i++) {
      const line1 = lines[i];
      const [x1, y1] = [line1.x1, line1.y1];
      const [x2, y2] = [line1.x2, line1.y2];

      for (let j = i + 1; j < lines.length; j++) {
          const line2 = lines[j];
          const [x3, y3] = [line2.x1, line2.y1];
          const [x4, y4] = [line2.x2, line2.y2];

          // Check if any endpoints match
          if (x1 === x3 && y1 === y3) {
              addUniqueCorner(x1, y1);
          }
          if (x1 === x4 && y1 === y4) {
              addUniqueCorner(x1, y1);
          }
          if (x2 === x3 && y2 === y3) {
              addUniqueCorner(x2, y2);
          }
          if (x2 === x4 && y2 === y4) {
              addUniqueCorner(x2, y2);
          }
      }
  }

  return uniqueCorners.size;
}

function areLinesEqual(line1, line2) {
  return (
    line1.x1 === line2.x1 &&
    line1.y1 === line2.y1 &&
    line1.x2 === line2.x2 &&
    line1.y2 === line2.y2
  );
}

let startMouseX = 0;
let startMouseY = 0;

function mouseMovementDirection(event) {
  const threshold = 0;

  const deltaX = event.clientX - startMouseX;
  const deltaY = event.clientY - startMouseY;

  if (Math.abs(deltaX) > threshold || Math.abs(deltaY) > threshold) {

    startMouseX = event.clientX;
    startMouseY = event.clientY;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      return {
        axis: 'x',
        direction: deltaX > 0 ? 'right' : 'left'
      };
    } else {
      return {
        axis: 'y',
        direction: deltaY > 0 ? 'down' : 'up'
      };
    }
  }
  return { axis: '', direction: '' };
}

function checkLineStraightness(originalVector, x1, y1, pointX, pointY) {
  const angleThreshold = 4;
  var currentVector = { x: pointX - x1, y: pointY - y1 };

  var dotProduct = originalVector.x * currentVector.x + originalVector.y * currentVector.y;
  var originalVectorMagnitude = Math.sqrt(originalVector.x ** 2 + originalVector.y ** 2);
  var currentVectorMagnitude = Math.sqrt(currentVector.x ** 2 + currentVector.y ** 2);

  var cosTheta = dotProduct / (originalVectorMagnitude * currentVectorMagnitude);
  var angleDifference = Math.acos(cosTheta) * (180 / Math.PI);

  // Check both directions
  var reverseCurrentVector = { x: x1 - pointX, y: y1 - pointY };
  var reverseDotProduct = originalVector.x * reverseCurrentVector.x + originalVector.y * reverseCurrentVector.y;
  var reverseCosTheta = reverseDotProduct / (originalVectorMagnitude * Math.sqrt(reverseCurrentVector.x ** 2 + reverseCurrentVector.y ** 2));
  var reverseAngleDifference = Math.acos(reverseCosTheta) * (180 / Math.PI);

  return angleDifference <= angleThreshold || reverseAngleDifference <= angleThreshold;
}


// OLD STRAIGHT POST COUNTER
// function straightPostCounter(postElements) {
//   const posts = postElements.filter(item => item.direction === '' && item.image_type === '')
//   return posts.length
// }

function straightPostCounter(allPosts, cornerPostCount) {
  return allPosts.length - cornerPostCount;
}

function calculateAngle(line) {
  return Math.atan2(line.y2 - line.y1, line.x2 - line.x1);
}

function calculateAngleBetweenLines(line1, line2) {
  var angle1 = calculateAngle(line1.x1, line1.y1, line1.x2, line1.y2);
  var angle2 = calculateAngle(line2.x1, line2.y1, line2.x2, line2.y2);

  // Calculate the angle between the lines
  var angleBetweenLines = Math.abs(angle1 - angle2);

  // Ensure the angle is between 0 and 180 degrees
  if (angleBetweenLines > Math.PI) {
    angleBetweenLines = 2 * Math.PI - angleBetweenLines;
  }

  return angleBetweenLines;
}

function areArraysEmpty(arrays) {

  const allEmpty = arrays.every(subarray => subarray.every(item => item === undefined || item === null));


  return allEmpty;
}

function findNearestIntersectionCoordinate(canvas, rows, startingCoordinate, isLeftOrDown, numberOfCells) {
  // Calculate the width of each cell
  var cellWidth = canvas.width / rows;

  // Calculate the end coordinate based on the direction
  var endCoordinate;

  if (isLeftOrDown) {
    // Moving left/down
    endCoordinate = startingCoordinate + (cellWidth * numberOfCells);
  } else {
    // Moving right/up
    endCoordinate = startingCoordinate - (cellWidth * numberOfCells);
  }

  // Ensure the coordinate is within the canvas bounds
  // endCoordinate = Math.max(0, Math.min(endCoordinate, canvas.width));

  return endCoordinate;
}

function findNearestIntersectionCoordinate2(canvas, rows, x, y) {
  var cellWidth = canvas.width / rows;
  var nearestX = Math.round(x / cellWidth) * cellWidth;
  var nearestY = Math.round(y / cellWidth) * cellWidth;
  return { x: nearestX, y: nearestY };
}

function getAngleInDegrees(line) {
  // Calculate the differences in x and y
  var deltaX = line.x2 - line.x1;
  var deltaY = line.y2 - line.y1;

  // Calculate the angle in radians
  var angleInRadians = Math.atan2(deltaY, deltaX);

  // Convert radians to degrees
  var angleInDegrees = angleInRadians * (180 / Math.PI);

  // Ensure the angle is between 0 and 360 degrees
  angleInDegrees = (angleInDegrees + 360) % 360;

  return angleInDegrees;
}

function isOutOfCanvas(canvas, x, y) {
  return (x <= 0 || x >= canvas.width || y <= 0 || y >= canvas.height);
}

function cutRails(rails, railLength) {
  let totalLength = 0;

  // Calculate the total length of all rails
  for (const rail of rails) {
      // Convert rail to number if it's not already
      const railNum = Number(rail);
      if (!isNaN(railNum)) { // Check if conversion is successful
          totalLength += railNum;
      }
  }

  // Calculate the number of rails needed, rounding up if necessary
  let railsUsed = Math.ceil(totalLength / railLength);

  return railsUsed;
}

function getActualLengths(canvas, cell, elements){
  let rails = [];
  elements.map((item, index) => {
    const totalLength = Math.sqrt(Math.pow(item.x2 - item.x1, 2) + Math.pow(item.y2 - item.y1, 2))
    let actualLength = Math.floor((Math.round((totalLength / (canvas.width / cell) * 1000) * 100) / 100))

    if(item.measurement !== ''){
      actualLength = item.measurement;
    }
    rails.push(actualLength)
  })
  return rails;
}


function countAllOffAxis(elements, postElements = [], postImageSize = 0, stroke = 0){
  let counter = 0;
  let betweenLinesCounter = 0;
  postElements.map((item, index) => {
      const lines = findLinesWithCoordinate(elements, item.x1 + postImageSize / 2, item.y1 + postImageSize / 2, stroke)
      if(lines.length > 0){
        lines.map((line, index) => {
          if(!isStraightLine(line)){
            counter++
          }
        })
      }else{
        elements.forEach((line, index) => {
          if(!isStraightLine(line) && isCoordinateWithinLine(item.x1 + postImageSize / 2, item.y1 + postImageSize / 2, line.x1, line.y1, line.x2, line.y2, stroke)){
            betweenLinesCounter++
          }
        });
      }
  });
  return counter + betweenLinesCounter * 2;
}

function countAllStraightAxis(elements, postElements = [], postImageSize = 0, stroke = 0){
  let counter = 0;
  let betweenLinesCounter = 0;
  postElements.map((item, index) => {
      const lines = findLinesWithCoordinate(elements, item.x1 + postImageSize / 2, item.y1 + postImageSize / 2, stroke)
      if(lines.length > 0){
        lines.map((line, index) => {
          if(isStraightLine(line)){
            counter++
            }
        })
      }else{
        elements.forEach((line, index) => {
          if(isStraightLine(line) && isCoordinateWithinLine(item.x1 + postImageSize / 2, item.y1 + postImageSize / 2, line.x1, line.y1, line.x2, line.y2, stroke)){
            betweenLinesCounter++
          }
        });
      }
      
  });

  return counter + betweenLinesCounter * 2;
}

// Function to check if a point is within a line segment (including slanting lines)
function isCoordinateWithinLine(x, y, x1, y1, x2, y2, strokeWidth) {
  // Calculate distance between point and line
  var numerator = Math.abs((x2 - x1) * (y1 - y) - (x1 - x) * (y2 - y1));
  var denominator = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  var distance = numerator / denominator;

  return distance <= strokeWidth / 2;
}

// Function to find lines containing a specific point
function findLinesWithCoordinate(lines, x1, y1, strokeWidth) {
  var result = [];

  for (var i = 0; i < lines.length; i++) {
      var line = lines[i];
      // if (isCoordinateWithinLine(x1, y1, line.x1, line.y1, line.x2, line.y2, strokeWidth)) {
      //     result.push(line);
      // }
      if( (ceil(line.x1) === ceil(x1) && ceil(line.y1) === ceil(y1)) || (ceil(line.x2) === ceil(x1) && ceil(line.y2) === ceil(y1)) ){
        result.push(line);
      }
  }

  return result;
}

// Define a function to calculate the angle between two lines in degrees
function calculateDegreesAngle(line1, line2) {
  // Calculate the dot product of the two lines
  let dotProduct = (line1.x2 - line1.x1) * (line2.x2 - line2.x1) + (line1.y2 - line1.y1) * (line2.y2 - line2.y1);
  
  // Calculate the magnitudes of the two lines
  let magnitude1 = Math.sqrt(Math.pow(line1.x2 - line1.x1, 2) + Math.pow(line1.y2 - line1.y1, 2));
  let magnitude2 = Math.sqrt(Math.pow(line2.x2 - line2.x1, 2) + Math.pow(line2.y2 - line2.y1, 2));
  
  // Calculate the cosine of the angle
  let cosineTheta = dotProduct / (magnitude1 * magnitude2);
  
  // Calculate the angle in radians
  let angleRad = Math.acos(cosineTheta);
  
  // Convert the angle from radians to degrees
  let angleDeg = angleRad * (180 / Math.PI);
  
  return angleDeg;
}

function count90DegreeLines(lines) {
  let count = 0;

    // Iterate through all pairs of lines
    for (let i = 0; i < lines.length; i++) {
        for (let j = i + 1; j < lines.length; j++) {
            let line1 = lines[i];
            let line2 = lines[j];

            // Check if any pair of lines share an endpoint
            if (
                (line1.x1 === line2.x1 && line1.y1 === line2.y1) ||
                (line1.x1 === line2.x2 && line1.y1 === line2.y2) ||
                (line1.x2 === line2.x1 && line1.y2 === line2.y1) ||
                (line1.x2 === line2.x2 && line1.y2 === line2.y2)
            ) {
                // Check if the shared endpoint forms a right angle
                if (isRightAngle(line1, line2) && isStraightLine(line1) && isStraightLine(line2)) {
                    count++;
                }
            }
        }
    }

    return count * 2;
}

// Helper function to check if two lines form a right angle at a shared endpoint
function isRightAngle(line1, line2) {
    let dx1 = line1.x2 - line1.x1;
    let dy1 = line1.y2 - line1.y1;
    let dx2 = line2.x2 - line2.x1;
    let dy2 = line2.y2 - line2.y1;

    // Calculate dot product
    let dotProduct = dx1 * dx2 + dy1 * dy2;

    // Check if dot product is zero (indicating a right angle)
    return Math.abs(dotProduct) < 1;
}

function getTotalRail(array) {
  const sum = array.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
  return sum;
}

function roundToTwoDigits(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

function getSubstringIncludingIcon(str) {
  const iconIndex = str.lastIndexOf('icon');
  if (iconIndex !== -1) {
    return str.substring(0, iconIndex + 'icon'.length);
  }
  return str;
}

function App() {

  const [action, setAction] = useState('Post')
  const [boardWidth, setBoardWidth] = useState(0)
  const [boardHeight, setBoardHeight] = useState(0)
  const mdCell = 32
  const smCell = 10
  const initialCell = window.innerWidth >= 768 ? mdCell : smCell
  const [cell, setCell] = useState(initialCell)
  const [prevCell, setPrevCell] = useState(initialCell)
  let baseBox = cell * 9
  const mdPostImageSize = 40
  const smPostImageSize = 30
  const initialPostImageSize = window.innerWidth >= 768 ? mdPostImageSize : smPostImageSize
  const [postImageSize, setPostImageSize] = useState(initialPostImageSize)
  const mdStroke = 22
  const smStroke = 18
  const initialStroke = window.innerWidth >= 768 ? mdStroke : smStroke
  const [stroke, setStroke] = useState(initialStroke)
  const [elements, setElements] = useState([])
  const [postElements, setPostElements] = useState([])
  const [gateElements, setGateElements] = useState([])
  const [drawing, setDrawing] = useState(false)
  const [canvas, setCanvas] = useState(null)
  const [inputLength, setInputLength] = useState(false)
  const [lastDistance, setLastDistance] = useState(null)
  const initialItemList = { "totalLength": 0, "straightPost": 0, "cornerPost": 0, "rails": [], "lastRail": 0, "gates": 0, "rail": 0, "totalRail": 0, "offAxisRail": 0, "straightAxisRail": 0, "angleBracket": 0 }
  const [itemList, setItemList] = useState(initialItemList)
  const [showCheckOut, setShowCheckOut] = useState(false)
  const [lastRailInput, setLastRailInput] = useState(0)
  const [secondClick, setSecondClick] = useState(true)
  const [tempGate, setTempGate] = useState([])
  const [canAddGate, setCanAddGate] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [clickedLine, setClickedLine] = useState(null)
  const [connectedLine, setConnectedLine] = useState(null)
  const [zoomTrigger, setZoomTrigger] = useState(false)
  const initialPercent = 1
  const [percent, setPercent] = useState(initialPercent)
  const [allProducts, setAllProducts] = useState([])
  const [products, setProducts] = useState([])
  const [loadInitial, setLoadInitial] = useState(false)
  const [continueProcess, setContinueProcess] = useState(true)
  const [canAdjustSpan, setCanAdjustSpan] = useState(true)
  const [showCantAdjustText, setShowCantAdjustText] = useState(false)
  const [adjustGate, setAdjustGate] = useState(false)
  const [flipGate, setFlipGate] = useState(false)
  const [dragSpan, setDragSpan] = useState(false)
  const [canDragSpan, setCanDragSpan] = useState(null)
  const [removedPosts, setRemovedPosts] = useState(0)
  const [clickedModal, setClickedModal] = useState(null)
  const [canvasImage, setCanvasImage] = useState(null)
  const [formData, setFormData] = useState({ "name": "", "email": "", "phone": "", "canvas": "", "items": [], 'color': '' })
  const [successFormSubmit, setSuccessFormSubmit] = useState("init")
  const mailer_url = process.env.REACT_APP_MAILER_URL
  const initialGateWidth = 49
  const [theGateWidth, setTheGateWidth] = useState(initialGateWidth)
  const initialGateHeight = 52
  const [theGateHeight, setTheGateHeight] = useState(initialGateHeight)
  const initialGateOffset = 32
  const [gateOffset, setGateOffset] = useState(initialGateOffset)
  const [gateOnQueue, setGateOnQueue] = useState(null)
  const [gateCurrentEvent, setGateCurrentEvent] = useState(null)
  const [grabbing, setGrabbing] = useState(false)
  const [inputString, setInputString] = useState('')
  const [resetPostsOnGrab, setResetPostsOnGrab] = useState(false)
  const [linesConnectedOnGrab, setLinesConnectedOnGrab] = useState([])
  const [clickedLineHistory, setClickedLineHistory] = useState(null)
  const [linesToBeRemoved, setLinesToBeRemoved] = useState([])
  const [dragHistory, setDragHistory] = useState(null)
  const [oppositeDirectionSpan, setOppositeDirectionSpan] = useState(false)
  const [dragPostHistory, setDragPostHistory] = useState(null)
  const [onLine, setOnLine] = useState(false)
  const [groupCounter, setGroupCounter] = useState(0)
  const [screenSize, setScreenSize] = useState(window.innerWidth >= 768 ? 'md' : 'sm')
  const [grabPointY, setgrabPointY] = useState(0)
  const [grabPointX, setgrabPointX] = useState(0)
  const [showUndo, setShowUndo] = useState(false)
  const [elementsHistory, setElementsHistory] = useState([])
  const [postElementsHistory, setPostElementsHistory] = useState([])
  const [cellHistory, setCellHistory] = useState([])
  const [itemListHistory, setItemListHistory] = useState([])
  const [strokeHistory, setStrokeHistory] = useState([])
  const [postImageSizeHistory, setPostImageSizeHistory] = useState([])
  const [manualInputValue, setManualInputValue] = useState(0)
  const [lastTapTime, setLastTapTime] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [init, setInit] = useState(false)
  const mdInitialMaxZoomOut = 40
  const smInitialMaxZoomOut = 24
  const initialMaxZoomOut = window.innerWidth >= 768 ? mdInitialMaxZoomOut : smInitialMaxZoomOut
  const [maxZoomOut, setMaxZoomOut] = useState(initialMaxZoomOut)
  const railLength = 3000
  const colorOptions = ["blue", "green", "red", "yellow"];
  const [hasDoubleClicked, setHasDoubleClicked] = useState(false)

  const handleAction = (action) => {
    setAction(action)
    setAdjustGate(false)
    setFlipGate(false)
    setGateOnQueue(null)
    setInputLength(false)
    setOnLine(false)
  }
  useLayoutEffect(() => {
    api.get('products?per_page=100')
      .then((response) => {
        const products = response.data;
  
        const flattenFilterValues = (filter) => {
          return Object.values(filter).flatMap(value => 
            typeof value === 'string' ? [value] : Object.values(value)
          );
        };
  
        const filterValues = flattenFilterValues(PRODUCT_FILTER);
        const filteredProducts = products.filter(product => 
          filterValues.includes(product.sku)
        );
        setProducts(filteredProducts);
      })
      .catch(error => console.log(error));
  }, []);

  useLayoutEffect(() => {
    if(!isTouchDevice()){
      const checkWidth = () => {
        clearCanvas()
        if(window.innerWidth < 768){
          setCell(smCell)
          setPrevCell(smCell)
          setPostImageSize(smPostImageSize)
          setStroke(smStroke)
          setPercent(initialPercent)
          setMaxZoomOut(smInitialMaxZoomOut)
          setScreenSize('sm')
        }else{
          setCell(mdCell)
          setPrevCell(mdCell)
          setPostImageSize(mdPostImageSize)
          setStroke(mdStroke)
          setPercent(initialPercent)
          setMaxZoomOut(mdInitialMaxZoomOut)
          setScreenSize('md')
        }
      }
      window.addEventListener('resize', checkWidth);
      checkWidth();
      return () => window.removeEventListener('resize', checkWidth);
    }
  }, []);

  const generateEcomURL = (products, itemList, color) => {
    if (products && itemList) {
      const floglass_url = process.env.REACT_APP_WC_URL;
  
      const getProductSku = (productType) => {
        const product = PRODUCT_FILTER[productType];
        if (typeof product === 'object' && product[color]) {
          return product[color];
        } else {
          return product;
        }
      };
    
      const productIds = {
        topBottomRailsId: products.find(item => item.sku === getProductSku('topBottomRails'))?.id || '',
        postsId: products.find(item => item.sku === getProductSku('posts'))?.id || '',
        glassSetId: products.find(item => item.sku === getProductSku('glassSet'))?.id || '',
        straightBracketId: products.find(item => item.sku === getProductSku('straightBracket'))?.id || '',
        adjustableAngleBracketId: products.find(item => item.sku === getProductSku('adjustableAngleBracket'))?.id || '',
        degreeBracketProductId: products.find(item => item.sku === getProductSku('degreeBracket'))?.id || '',
      };
  
      const bracketCount = itemList['angleBracket'] || 0;
      const straightBracketCount = itemList['straightAxisRail'] - bracketCount || 0;
      const adjustableAngleBracketCount = itemList['offAxisRail'] + bracketCount || 0;
  
      const urlParts = [];
  
      if (itemList['rail'] > 0) {
        urlParts.push(`${productIds.topBottomRailsId}:${itemList['rail']}`);
      }
  
      if (itemList['straightPost'] + itemList['cornerPost'] > 0) {
        urlParts.push(`${productIds.postsId}:${itemList['straightPost'] + itemList['cornerPost']}`);
      }
  
      if (itemList['totalRail'] > 0) {
        urlParts.push(`${productIds.glassSetId}:${Math.ceil(itemList['totalRail'] / 500)}`);
      }
  
      if (straightBracketCount > 0) {
        urlParts.push(`${productIds.straightBracketId}:${straightBracketCount}`);
      }
  
      if (adjustableAngleBracketCount > 0 ) {
        urlParts.push(`${productIds.adjustableAngleBracketId}:${adjustableAngleBracketCount}`);
      }
  
      // 90 DEGREE
      // if (bracketCount > 0) {
      //   urlParts.push(`${productIds.degreeBracketProductId}:${bracketCount}`);
      // }
  
      const encodedParts = urlParts.map(part => encodeURIComponent(part));
      const url = `${floglass_url}cart/?add-to-cart=${encodedParts.join(',')}`;
  
      return url;
    }
  
    return null;
  };
  

  useLayoutEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:400,600,700,800']
      }
    });
   }, []);

  useLayoutEffect(() => {
   
    const canvasContainer = document.getElementById("canvasContainer")
    const dimension = canvasDimension(canvasContainer)
    setBoardWidth(dimension.width)
    setBoardHeight(dimension.height)
    const canvas = document.getElementById("canvas")
    setCanvas(canvas)
    
    const meterView = document.getElementById("meterView")
    meterView.style.left = canvas.width + canvas.offsetLeft - (screenSize === 'md' ? 155 : 125) + "px"
    meterView.style.top = canvas.height + canvas.offsetTop - (screenSize === 'md' ? 35 : 20) + "px"

    const zoomContainer = document.getElementById("zoomContainer")
    zoomContainer.style.left = canvas.width + canvas.offsetLeft - 85 + "px"
    zoomContainer.style.top = canvas.height + canvas.offsetTop - (screenSize === 'md' ? 70 : 50) + "px"

    const context = canvas.getContext("2d")
    context.font = '14px Montserrat'
    context.fillStyle = '#54bcbe'
    context.clearRect(0, 0, canvas.width, canvas.height)

    context.beginPath()

    if(!init){
      if (products.length === 0) {
        if(!isTouchDevice()){
          document.documentElement.style.cursor = "wait"
        }
        return
      } else {
        if (action === 'Post') {
          if(!isTouchDevice()){
            document.documentElement.style.cursor = "default"
            setInit(true)
          }
        }
        drawBoard(canvas, cell)
      }
    }else{
      drawBoard(canvas, cell)
    }
    
    const roughCanvas = rough.canvas(canvas)

    elements.forEach(({ roughElement }) => roughCanvas.draw(roughElement))
    
    gateElements.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
    tempGate.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
    postElements.forEach((el) => context.drawImage(el.image, el.x1, el.y1, el.x2, el.y2))
 
    // INDICATORS
    elements.map((item, index) => {
      const totalLength = Math.sqrt(Math.pow(item.x2 - item.x1, 2) + Math.pow(item.y2 - item.y1, 2))
      let actualLength = Math.floor((Math.round((totalLength / (canvas.width / cell) * 1000) * 100) / 100))

      if(item.measurement !== ''){
        actualLength = item.measurement;
      }

      let angle = calculateAngle(item);
      let labelX = (item.x1 + item.x2) / 2;
      let labelY = (item.y1 + item.y2) / 2;

      let offset = stroke / 2;

      context.save();
      context.translate(labelX, labelY);
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillStyle = '#F5F5DC';
      if (Math.abs(item.y2 - item.y1) > Math.abs(item.x2 - item.x1)) {
        // Line is closer to vertical
        context.rotate(angle);
        context.fillText(actualLength + ' mm', 0, -offset + stroke / 2);
      } else {
        // Line is closer to horizontal
        context.rotate(angle + (item.x1 < item.x2 ? 0 : Math.PI)); // Rotate by 180 degrees if line goes from left to right
        context.fillText(actualLength + ' mm', 0, offset - stroke / 2);
      }
  
      context.restore();
    })

  }, [cell, boardWidth, boardHeight, elements, elements.roughElement, postElements, gateElements, canvas, tempGate, products, screenSize])

  useLayoutEffect(() => {
    if (zoomTrigger) {
    
      const canvas = document.getElementById("canvas")
      const cellLength = canvas.width / cell
      const prevCellLength = canvas.width / prevCell
      const elementsCopy = [...elements]
      const postElementsCopy = [...postElements]
      const gateElementsCopy = [...gateElements]
      const newElements = []
      const newPostElements = []
      const newGateElements = []

      let percent = cellLength / prevCellLength
      const stroke1 = Math.round(stroke * percent * 100) / 100

      elementsCopy.forEach((item, index) => {
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const group = item.group
        const measurement = item.measurement
        const color = "dark"
        const el = createElement(x1, y1, x2, y2, line, group, stroke1, color, measurement)
        newElements.push(el)
      })
   
      postElementsCopy.forEach((item, index) => {
        const postId = item.postId
        const image = item.image
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const type = item.image_type
        const direction = item.direction
        const postEl = createPostElement(postId, image, x1, y1, x2, y2, line, type, direction)
        newPostElements.push(postEl)
      })

      gateElementsCopy.forEach((item, index) => {
        const postId = item.postId
        const image = item.image
        const x1 = item.x1 * percent
        const y1 = item.y1 * percent
        const x2 = item.x2 * percent
        const y2 = item.y2 * percent
        const line = item.line
        const image_type = item.image_type
        const postEl = createPostElement(postId, image, x1, y1, x2, y2, line, image_type)
        newGateElements.push(postEl)
      })
  
      setElements(newElements)
      setPostElements(newPostElements)
      setGateElements(newGateElements)

      setPostImageSize(postImageSize * percent)
      setStroke(stroke1)
      
      setGateOffset(gateOffset * percent)
      setTheGateHeight(theGateHeight * percent)
      setTheGateWidth(theGateWidth * percent)
      setPercent(percent)
      setZoomTrigger(false)
    }
  }, [zoomTrigger])

  useLayoutEffect(() => {
    setItemList(prevState => ({ ...prevState, gates: gateElements.length }))
  }, [gateElements])

  useLayoutEffect(() => {
    if (!inputLength) return
    const { clientX, clientY } = currentEvent

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    const input = document.getElementById('inputField')
    const button = document.getElementById('inputFieldBtn')

    input.style.width = screenSize === "md" ? "200px" : "170px"
    input.style.left = screenSize === "md" ? (pointX + canvas.offsetLeft + 40 + "px") : "calc(50% - 28px)"
    input.style.top = screenSize === "md" ? (pointY + canvas.offsetTop - 50 + "px") : canvas.offsetTop + 20 + "px"

    button.style.left = screenSize === "md" ? (pointX + canvas.offsetLeft + 230 + "px") : "calc(50% + 82px)"
    button.style.top = screenSize === "md" ? (pointY + canvas.offsetTop - 50 + "px") : canvas.offsetTop + 20 + "px"
    button.style.borderRadius = "0"

    if(screenSize === "sm"){
      input.style.transform = "translateX(-50%)"
      button.style.transform = "translateX(-50%)"
    }

    setSecondClick(false)

  }, [inputLength, canvas, cell, currentEvent])

  const clearCanvas = () => {
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d")

    context.clearRect(0, 0, canvas.width, canvas.height)
    setShowCheckOut(false)
    setElements([])
    setPostElements([])
    setGateElements([])
    drawBoard(canvas, cell)
    setInputLength(false)
    setItemList(initialItemList)
    setTempGate([])
    setLastRailInput(0)
    setSecondClick(true)
    setTempGate([])
    setCanAddGate(false)
    setCurrentEvent(null)
    setClickedLine(null)
    setConnectedLine(null)
    setCell(initialCell)
    setPrevCell(initialCell)
    setPercent(initialPercent)
    setStroke(initialStroke)
    setPostImageSize(initialPostImageSize)
    setContinueProcess(true)
    setCanAdjustSpan(true)
    setShowCantAdjustText(false)
    setAction("Post")
    setAdjustGate(false)
    setFlipGate(false)
    setDragSpan(false)
    setCanDragSpan(null)
    setRemovedPosts(0)
    setFormData({ "name": "", "email": "", "phone": "", "canvas": "", "items": [], "color": "" })
    setSuccessFormSubmit("init")
    setGateOffset(initialGateOffset)
    setGateOnQueue(null)
    setGateCurrentEvent(null)
    setTheGateWidth(initialGateWidth)
    setTheGateHeight(initialGateHeight)
    setResetPostsOnGrab(false)
    setLinesConnectedOnGrab([])
    setGrabbing(false)
    setClickedLineHistory(null)
    setLinesToBeRemoved([])
    setDragHistory(null)
    setOppositeDirectionSpan(false)
    setDragPostHistory(null)
    setOnLine(false)
    setGroupCounter(0)
    setShowUndo(false)
    setElementsHistory([])
    setPostElementsHistory([])
    setCellHistory([])
    setItemListHistory([])
    setStrokeHistory([])
    setPostImageSizeHistory([])
    setManualInputValue(0)
    setMaxZoomOut(initialMaxZoomOut)
    setHasDoubleClicked(false)
  }

  const focusInputField = () => {
    const field = document.getElementById('inputField')
    field.focus()
  }

  const handleInput = (e) => {
    let value = e.target.value
    
    if (value !== '') {
      value = value.replace(/\D/g, "")
      if(value > 90000){
        value = '90000';
      }
      setSecondClick(false)
      setLastDistance(value)
    }
    if(value > 90000){
      value = '90000';
    }
    setManualInputValue(0)
    setInputString(value)
  }

  const handleBlur = (e) => {
    let value = inputString
    let recreated = [];
    value = value.replace(/\D/g, "")
    
    if (value !== '' && value !== '0') {
      if(value < 100){
        value = 100
      }else if (value > 90000){
        value = 90000
      }
  
      const currentElements = elements.map(element => ({ ...element }));
      const currentPostElements = postElements.map(element => ({ ...element }));

      setElementsHistory([...elementsHistory, currentElements])
      setPostElementsHistory([...postElementsHistory, currentPostElements])
      setCellHistory(prevCellHistory => [...prevCellHistory, cell])
      setItemListHistory(prevItemListHistory => [...prevItemListHistory, itemList])
      setStrokeHistory(prevStrokeHistory => [...prevStrokeHistory, stroke])
      setPostImageSizeHistory(prevPostImageSizeHistory => [...prevPostImageSizeHistory, postImageSize])
      
      const equivalent_value = value * (canvas.width / cell / 1000)
      let actualLength = (Math.ceil(equivalent_value / (canvas.width / cell)))
      actualLength = actualLength < 1 ? 1 : actualLength
      const rawLength = roundToTwoDigits(equivalent_value / (canvas.width / cell))
      const start_connected = elements.filter(item => ((ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.y1) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1))) && item.line !== clickedLine.line)
      const end_connected = elements.filter(item => ((ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2)) || (ceil(item.x1) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y2))) && item.line !== clickedLine.line)

      const postImageY = new Image()
      const postImageX = new Image()
      const postImageZLR = new Image()
      const postImageZRL = new Image()
      postImageY.src = post_y_icon
      postImageX.src = post_x_icon
      postImageZLR.src = post_z_lr_icon
      postImageZRL.src = post_z_rl_icon
      postImageY.filename = 'post_y_icon'
      postImageX.filename = 'post_x_icon'
      postImageZLR.filename = 'post_z_lr_icon'
      postImageZRL.filename = 'post_z_rl_icon'
      
      let elementsCopy = [...elements]
      let postElementsCopy = [...postElements]
      let railCopy = itemList.rails ? [...itemList.rails] : []
      const pushedLines = [clickedLine.line]

      elementsCopy.forEach((item, index) => {
        const angle = calculateAngle(item);
        
        let ax = item.x1
        let ay = item.y1
        let bx = item.x2
        let by = item.y2

        if (item.x1 === clickedLine.x1 && item.x2 === clickedLine.x2 && item.y1 === clickedLine.y1 && item.y2 === clickedLine.y2) {
          pushedLines.push(item.line)

          const item_start_connected = elements.filter(i => ((ceil(i.x2) === ceil(item.x1) && ceil(i.y2) === ceil(item.y1)) || (ceil(i.x1) === ceil(item.x1) && ceil(i.y1) === ceil(item.y1))) && i.line !== item.line)

          const item_end_connected = elements.filter(i => ((ceil(i.x2) === ceil(item.x2) && ceil(i.y2) === ceil(item.y2)) || (ceil(i.x1) === ceil(item.x2) && ceil(i.y1) === ceil(item.y2))) && i.line !== item.line)

          let measurement = '';

          if(item_end_connected.length > 0){
            if(!isStraightLine(item)){
              ax = item.x2 - equivalent_value * Math.cos(angle);
              ay = item.y2 - equivalent_value * Math.sin(angle);
            }else if(ax === bx){
              ax = bx
              ay = item.y2 - equivalent_value
            }else if(ay == by){
              ax = item.x2 - equivalent_value
              ay = by
            }
  
          }else{
      
            if(!isStraightLine(item)){
              measurement = value
              let equivalent_value1 = actualLength * (canvas.width / cell);
              bx = item.x1 + equivalent_value1 * Math.cos(angle);
              by = item.y1 + equivalent_value1 * Math.sin(angle);

              let nearestIntersection = findNearestIntersectionCoordinate2(canvas, cell, bx, by);
              bx = nearestIntersection.x
              by = nearestIntersection.y
 
            }else if(ax === bx){
              if(item.y1 > item.y2){
                bx = ax
                by = item.y1 - equivalent_value
              }else{
                bx = ax
                by = item.y1 + equivalent_value
              }
              
            }else if(ay === by){
              if(item.x1 > item.x2){
                bx = item.x1 - equivalent_value
                by = ay
              }else{
                bx = item.x1 + equivalent_value
                by = ay
              }
            }

            if(isStraightLine(item)){
     
              measurement = value

              if(ay === by){
                if(bx > ax){
                  bx = findNearestIntersectionCoordinate(canvas, cell, ax, true, actualLength)
                }else{
                  bx = findNearestIntersectionCoordinate(canvas, cell, ax, false, actualLength)
                }
              }else{
                if(by > ay){
                  by = findNearestIntersectionCoordinate(canvas, cell, ay, true, actualLength)
                }else{
                  by = findNearestIntersectionCoordinate(canvas, cell, ay, false, actualLength)
                }
              }
            }
          }
        
          recreated = createElement(ax, ay, bx, by, item.line, item.group, stroke, '', measurement)

          elementsCopy[index] = recreated
          elementsCopy[index]['roughElement'].options.stroke = '#03658c'
    
          let line = postElementsCopy.filter(item => ceil(item.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(item.y1) === ceil(clickedLine.y2 - postImageSize / 2))
          line = line[0].line

          let betweenPostsCtr = 0
          if (rawLength > 3 && rawLength <= 6) {
            betweenPostsCtr = 1
          } else if (rawLength > 6 && rawLength % 3 === 0) {
            betweenPostsCtr = Math.floor(rawLength / 3) - 1
          } else if (rawLength > 6 && rawLength % 3 > 0) {
            betweenPostsCtr = Math.floor(rawLength / 3)
          }
          let incrementX = (bx - ax) / (betweenPostsCtr + 1)
          let incrementY = (by - ay) / (betweenPostsCtr + 1)

          let betweenPosts = []

          const postId = postElements.length
          let image = postImageX
          let imageDirection = ''
          if (ay === by) {
            image = postImageY
          } else if ((ax < bx && ay < by) || (ax > bx && ay > by)) {
            image = postImageZRL
            imageDirection = 'corner'
          } else if ((ax > bx && ay < by) || (ax < bx && ay > by)) {
            image = postImageZLR
            imageDirection = 'corner'
          }
          const startExist = elements.filter(i => ((clickedLine.x1 === i.x1 && clickedLine.y1 === i.y1) || (clickedLine.x1 === i.x2 && clickedLine.y1 === i.y2)) && clickedLine.line !== i.line)

          if (startExist.length === 0) {
            const firstPostElement = createPostElement(postId, image, ax - postImageSize / 2, ay - (postImageSize / 2), postImageSize, postImageSize, line, '', imageDirection)
            betweenPosts.push(firstPostElement)

            if (end_connected.length !== 0) {
              let tempPost = postElementsCopy.filter(i => ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2))
              tempPost = tempPost[0]
              betweenPosts.push(tempPost)
            }
          } else {
            let tempPost = postElementsCopy.filter(i => ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2))
            tempPost = tempPost[0]
            betweenPosts.push(tempPost)
          }

          const loopCtr = end_connected.length !== 0 ? betweenPostsCtr - 1 : betweenPostsCtr

          for (let i = 0; i <= loopCtr; i++) {
            const postId = i === 0 && startExist.length === 0 ? postElements.length + i + 1 : postElements.length + i
            const postElement = createPostElement(postId, image, ax + incrementX * (i + 1) - (postImageSize / 2), ay + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, line, '', imageDirection)
            betweenPosts.push(postElement)
          }

          const removePosts = postElementsCopy.filter(item => item.line !== line)

          const allPosts = [...removePosts, ...betweenPosts]
          let uniquePosts= allPosts.filter((line, index, self) =>
            line ? self.findIndex(l => areLinesEqual(l, line)) === index : true
          );
        
          setPostElements(uniquePosts)

          const totalCornerPosts = cornerPostCounter(elementsCopy)
          const totalStraightPosts = straightPostCounter(uniquePosts, totalCornerPosts)
          const offAxisRail = countAllOffAxis(elementsCopy, uniquePosts, postImageSize, stroke)
          const straightAxisRail = countAllStraightAxis(elementsCopy, uniquePosts, postImageSize, stroke)
          

          // railCopy = railCopy.map(i =>
          //   i.line === clickedLine.line
          //     ? { ...i, distance: value, count: betweenPostsCtr + 1 }
          //     : i
          // )
          setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy, offAxisRail: offAxisRail, straightAxisRail: straightAxisRail }))
        
        }
      })

      const railActualLengths = getActualLengths(canvas, cell, elementsCopy)
      const rail = cutRails(railActualLengths, railLength)
      const totalRail = getTotalRail(railActualLengths)
      const angleBracket = count90DegreeLines(elementsCopy)
      setElements(elementsCopy)
      setItemList(prevState => ({ ...prevState, rail: rail, totalRail: totalRail, angleBracket: angleBracket }))
   
      // let gatessCopy = [...gateElements]
      // gatessCopy = gatessCopy.filter(item => !pushedLines.includes(item.line))
      // setGateElements(gatessCopy)
    
      if(recreated && isOutOfCanvas(canvas, recreated.x2, recreated.y2)){
        let cellLength = canvas.width / cell;

        let usedCellX = Math.ceil(recreated.x1 / cellLength) + 2;
        let usedCellY = Math.ceil(recreated.y1 / cellLength) + 2;

        let actualLengthX = Math.ceil(Math.abs(recreated.x2 - recreated.x1) / cellLength)
        let actualLengthY = Math.ceil(Math.abs(recreated.y2 - recreated.y1) / cellLength)

        let neededCellX = usedCellX + actualLengthX;
        let neededCellY = usedCellY + actualLengthY;

        let calculatedColumns = calculateColumns(canvas, neededCellY);
   
        let neededCells = calculatedColumns > neededCellX ? calculatedColumns : neededCellX;

        if(maxZoomOut < neededCells){
          setMaxZoomOut(neededCells)
        }

        setPrevCell(cell)
        setCell(neededCells)
        setZoomTrigger(true)
      }
    }

    setInputLength(false)
    setLastDistance(null)
    setSecondClick(true)
    setInputString('')
  }

  const handleInputFieldBlur = () => {
    const inputField = document.getElementById("inputField")
    
    inputField.focus();
    inputField.blur();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const inputField = document.getElementById("inputField")
      inputField.blur()
    }
  }

  const handleMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault()
    if(isTouchDevice() && !event.isPrimary){
      return
    }

    if (products.length === 0 || drawing) return
    if ((inputLength && lastDistance === null) || !secondClick) {
      focusInputField()
      return
    }
    const pos = getMousePos(canvas, event);

    const gateX = new Image()
    const gateY = new Image()
    const postImageY = new Image()
    const postImageX = new Image()
    const gateXF = new Image()
    const gateXFA = new Image()
    const gateXFB = new Image()
    const gateYF = new Image()
    const gateYFA = new Image()
    const gateYFB = new Image()

    gateX.src = gate_x
    gateY.src = gate_y
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    gateXF.src = gate_x_f
    gateXFA.src = gate_x_fa
    gateXFB.src = gate_x_fb
    gateYF.src = gate_y_f
    gateYFA.src = gate_y_fa
    gateYFB.src = gate_y_fb

    gateX.filename = 'gate_x'
    gateY.filename = 'gate_y'
    postImageY.filename = 'post_y_icon'
    postImageX.filename = 'post_x_icon'
    gateXF.filename = 'gate_x_f'
    gateXFA.filename = 'gate_x_fa'
    gateXFB.filename = 'gate_x_fb'
    gateYF.filename = 'gate_y_f'
    gateYFA.filename = 'gate_y_fa'
    gateYFB.filename = 'gate_y_fb'

    const clientX = pos.x
    const clientY = pos.y

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)
    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return

    const line = findLine(clientX, clientY, elements, stroke)
    
    if (action === 'Post') {

      const inPost = findInPost(clientX, clientY, postElements, elements, postImageSize)
   
      setClickedLine(null)
      setContinueProcess(true)
      setDragSpan(false)
      setCanDragSpan(null)
      setDragHistory(null)
      setDragPostHistory(null)
      setOnLine(false)
      setOppositeDirectionSpan(false)
      setShowCantAdjustText(false)
      setConnectedLine(null)

      const currentElements = elements.map(element => ({ ...element }));
      const currentPostElements = postElements.map(element => ({ ...element }));
      setElementsHistory([...elementsHistory, currentElements])
      setPostElementsHistory([...postElementsHistory, currentPostElements])
      setCellHistory(prevCellHistory => [...prevCellHistory, cell])
      setItemListHistory(prevItemListHistory => [...prevItemListHistory, itemList])
      setStrokeHistory(prevStrokeHistory => [...prevStrokeHistory, stroke])
      setPostImageSizeHistory(prevPostImageSizeHistory => [...prevPostImageSizeHistory, postImageSize])
      
      if (line.length > 0 && inPost.length === 0) {
        // DRAG SPAN
        setOnLine(true)
        
        // CHECK IF THERE IS SPAN CONNECTED
        const fromCorner = elements.filter(item => {
          const x1 = line[0].x1;
          const y1 = line[0].y1;
          const x2 = line[0].x2;
          const y2 = line[0].y2;
        
          // Check if the lines have any common points
          const haveCommonPoints =
            (x1 === item.x1 && y1 === item.y1) ||
            (x1 === item.x2 && y1 === item.y2) ||
            (x2 === item.x1 && y2 === item.y1) ||
            (x2 === item.x2 && y2 === item.y2);
        
          if (haveCommonPoints) {
            return true;
          }
        
          // Calculate the slopes of the lines
          const lineSlope = (y2 - y1) / (x2 - x1);
          const itemSlope = (item.y2 - item.y1) / (item.x2 - item.x1);
        
          // Check if the slopes are approximately equal (with a small tolerance)
          const slopeTolerance = 1e-10;
          if (Math.abs(lineSlope - itemSlope) < slopeTolerance) {
            return true;
          }
          return false;
        });
      
        if(elements.length > 1 && fromCorner.length > 0){
          setDrawing(true)
          setResetPostsOnGrab(true)
          setClickedLineHistory(line[0])
          setClickedLine(line[0])
          setgrabPointY(pointY)
          setgrabPointX(pointX)
          setLinesToBeRemoved([])
          
        }         

      } else if (line.length >= 1 && inPost.length > 0) {
        const removedPostsCtr = postElements.filter(item => item.line === inPost[0].line)
        const theLine = line.find(item => (ceil(item.x1 - postImageSize / 2) === ceil(inPost[0].x1) && ceil(item.y1 - postImageSize / 2) === ceil(inPost[0].y1)) || (ceil(item.x2 - postImageSize / 2) === ceil(inPost[0].x1) && ceil(item.y2 - postImageSize / 2) === ceil(inPost[0].y1)))
        
        setRemovedPosts(removedPostsCtr.length)
        const data = {
          "line": theLine,
          "post": inPost[0]
        }
 
        setCanDragSpan(data)
        setDragHistory(data)
        const postsRelated = postElements.filter(item => item.line === theLine.line)
        setDragPostHistory(postsRelated)

        const sameClickedPostLineEndX = ceil(theLine.x2) === ceil(inPost[0].x1 + postImageSize / 2)
        const sameClickedPostLineEndY = ceil(theLine.y2) === ceil(inPost[0].y1 + postImageSize / 2)

        let clickedPostX = sameClickedPostLineEndX ? theLine.x1 : theLine.x2
        let clickedPostY = sameClickedPostLineEndY ? theLine.y1 : theLine.y2

        let start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY) && item.line !== theLine.line)
        let end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY) && item.line !== theLine.line)

        // if(!start_connected.length){
        //   start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY))
        // }

        // if(!end_connected.length){
        //   end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY))
        // }
        const elementsInGroup = elements.filter(item => item.group === theLine.group)
        if(elementsInGroup.length < 2){
          start_connected = elements.filter(item => ceil(item.x1) === ceil(clickedPostX) && ceil(item.y1) === ceil(clickedPostY))
          end_connected = elements.filter(item => ceil(item.x2) === ceil(clickedPostX) && ceil(item.y2) === ceil(clickedPostY))
        }  
        if(!sameClickedPostLineEndX && !sameClickedPostLineEndY){
          if (start_connected.length > 0) {
            setConnectedLine(start_connected)
          }else if(end_connected.length > 0) {
            setConnectedLine(end_connected)
          }
        }else{
          if (end_connected.length > 0) {
            setConnectedLine(end_connected)
          } else if (start_connected.length > 0) {
            setConnectedLine(start_connected)
          }
        }
        setDragSpan(true)
        setDrawing(true)
        
        return
      }else if(elements.length === 0 || (line.length === 0 && inPost.length === 0 && elements.length > 0)) {
        const postImageY = new Image()
        const postImageZLRL = new Image()
        postImageY.src = post_y_icon
        postImageZLRL.src = post_z_lr_light_icon
        postImageY.filename = 'post_y_icon'
        postImageZLRL.filename = 'post_z_lr_light_icon'
        postImageY.onload = () => {

          setContinueProcess(true)
          const postId = postElements.length
          const postElement = createPostElement(postId, postImageY, pointX, pointY, postImageSize, postImageSize)
          const positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
          const indexLine = elements.length

          // if (!positionElement && postElements.length === 0) {
          if (!positionElement) {
            setDrawing(true)
            setPostElements(prevState => [...prevState, postElement])
            const element = createElement(pointX + postImageSize / 2, pointY + postImageSize / 2, pointX + postImageSize / 2, pointY + postImageSize / 2, indexLine, groupCounter, stroke)

            setGroupCounter(groupCounter + 1)

            setElements(prevState => [...prevState, element])
          } else if (positionElement || inPost.length !== 0) {
            if (line.length > 0 && inPost.length === 0) {
              if (line[0].x1 !== line[0].x2) {
                if (positionElement.x1 + postImageSize / 2 > line[0].x1 && positionElement.x1 + postImageSize / 2 < line[0].x2 || positionElement.x1 + postImageSize / 2 < line[0].x1 && positionElement.x1 + postImageSize / 2 > line[0].x2) {
                  setContinueProcess(false)
                  return
                }
              } else {
                if (positionElement.y1 + postImageSize / 2 > line[0].y1 && positionElement.y1 + postImageSize / 2 < line[0].y2 || positionElement.y1 + postImageSize / 2 < line[0].y1 && positionElement.y1 + postImageSize / 2 > line[0].y2) {
                  setContinueProcess(false)
                  return
                }
              }
            }

            const x = inPost.length === 0 ? pointX : inPost[0].x1
            const y = inPost.length === 0 ? pointY : inPost[0].y1

            const postElement1 = createPostElement(postId, postImageZLRL, x, y, postImageSize, postImageSize, "tmp")

            setPostElements(prevState => [...prevState, postElement1])

            const centerOfPost = postImageSize / 2

            const currentPost = postElements.filter(item => ceil(item.x1) === ceil(x))

            if (currentPost[0]) {
              setDrawing(true)
              
              const element = createElement(x + centerOfPost, y + centerOfPost, x + centerOfPost, y + centerOfPost, indexLine, groupCounter, stroke)
              setGroupCounter(groupCounter + 1)
              setElements(prevState => [...prevState, element])

              const start_connected = elements.filter(item => ceil(item.x1) === ceil(element.x1) && ceil(item.y1) === ceil(element.y1))
              const end_connected = elements.filter(item => ceil(item.x2) === ceil(element.x2) && ceil(item.y2) === ceil(element.y2))

              if (start_connected.length > 0) {
                setConnectedLine(start_connected)
              } else if (end_connected.length > 0) {
                setConnectedLine(end_connected)
              }
            }
            
          }
        }
      }else{
        setContinueProcess(false)
      }

    } else if (action === 'Gate') {
      setGateOnQueue(null)
      if (line.length > 0) {
        const existingGate = gateElements.filter(item => (clientX >= item.x1 && clientX <= item.x1 + item.x2 && (ceil(line[0].y1 - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 - gateOffset - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 + gateOffset - (postImageSize * .25)) === ceil(item.y1))) || (clientY >= item.y1 && clientY <= item.y1 + item.y2 && (ceil(line[0].x1 - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 - gateOffset - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 + gateOffset - (postImageSize * .25)) === ceil(item.x1))))

        if (existingGate.length > 0) {
          setGateOnQueue(existingGate[0])
          setGateCurrentEvent({
            "clientX": !event.touches ? event.pageX : event.touches[0].clientX, 
            "clientY": !event.touches ? event.pageY : event.touches[0].clientY
          })
          return

        }

      }

      gateX.onload = () => {
        if (tempGate.length > 0 && canAddGate) {
 
          const copyTemp = [...tempGate]

          const gateExistingInGate = gateElements.filter(item => ceil(item.x1) === ceil(copyTemp[0].x1) && ceil(item.y1) === ceil(copyTemp[0].y1))

          const gateExistingInPost = postElements.filter(item => (item.x1 + (postImageSize / 2) > copyTemp[0].x1 && item.x1 + postImageSize / 2 < copyTemp[0].x1 + copyTemp[0].x2 && ceil(item.y1 + (postImageSize * .25)) === ceil(copyTemp[0].y1)) || (item.y1 + (postImageSize / 2) > copyTemp[0].y1 && item.y1 + postImageSize / 2 < copyTemp[0].y1 + copyTemp[0].y2 && ceil(item.x1 + (postImageSize * .25)) === ceil(copyTemp[0].x1)))

          if(gateExistingInPost.length > 0 || gateExistingInGate.length > 0){
            setCanAddGate(false)
            setTempGate([])
            return
          }

          const newGates = []
          let postCount = 1
          let posts = []
          let postElement = []
          const postId = 'gate_post'
          const index = line[0].line

          if (copyTemp[0].x2 < copyTemp[0].y2) {
      
            posts = postElements.filter(item => ceil(item.y1) === ceil(copyTemp[0].y1 - (postImageSize * .25)) && (ceil(item.x1) === ceil(copyTemp[0].x1 - postImageSize / 2) || ceil(item.x1) === ceil(copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2)))

            if (posts.length === 0) {
              postCount = 2

              const postElement1 = createPostElement(postId, postImageY, copyTemp[0].x1 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              const postElement2 = createPostElement(postId, postImageY, copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              newGates.push(postElement1)
              newGates.push(postElement2)


            } else if (posts.length === 1) {

              if (posts[0].x1 > copyTemp[0].x1) {
                postElement = createPostElement(postId, postImageY, copyTemp[0].x1 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              } else {
                postElement = createPostElement(postId, postImageY, copyTemp[0].x1 + copyTemp[0].x2 - postImageSize / 2, line[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              }

              newGates.push(postElement)
            }

            copyTemp[0].image = gateX
          } else {

            posts = postElements.filter(item => ceil(item.x1) === ceil(copyTemp[0].x1 - (postImageSize * .25)) && (ceil(item.y1) === ceil(copyTemp[0].y1 - postImageSize / 2) || ceil(item.y1) === ceil(copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2)))
            if (posts.length === 0) {
              postCount = 2

              const postElement1 = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)

              const postElement2 = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2, postImageSize, postImageSize, index)

              newGates.push(postElement1)
              newGates.push(postElement2)
             
            } else if (posts.length === 1) {

              if (posts[0].y1 > copyTemp[0].y1) {
                postElement = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 - postImageSize / 2, postImageSize, postImageSize, index)
              } else {
                postElement = createPostElement(postId, postImageX, line[0].x1 - postImageSize / 2, copyTemp[0].y1 + copyTemp[0].y2 - postImageSize / 2, postImageSize, postImageSize, index)
              }

              newGates.push(postElement)
            }

            copyTemp[0].image = gateY
          }    

          copyTemp[0].line = line[0].line

          setTempGate(copyTemp)

          setGateElements(prevState => [...prevState, tempGate[0]])

          setPostElements(prevState => [...prevState, ...newGates])
          setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost + postCount }))
          setTempGate([])
        }
      }
    }
  }

  const handleMouseMove = (event) => {
    
    let mouseMovement = mouseMovementDirection(event);

    if(isTouchDevice() && !event.isPrimary){
      return
    }
    
    let mouseDown = { x: null, y: null }

    const pos = getMousePos(canvas, event);

    const clientX = pos.x
    const clientY = pos.y
  
    let pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    let pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return
    
    if (action === 'Post') {
      
      
      if ((inputLength && lastDistance === null) || !secondClick) {
        focusInputField()
        return
      }
      if ((clientX !== mouseDown.x || clientY !== mouseDown.y) && !drawing) {
        // INLINE HOVER

      } else if (!drawing) return

      if (!drawing || elements.length === 0) return

      const lastHistory = elementsHistory[elementsHistory.length - 1] || [];

      if (elements.length - lastHistory.length >= 2) {
   
        setElements((prevElements) => {
          if (prevElements.length - lastHistory.length >= 2) {
            return prevElements.slice(0, -1); 
          }
          return prevElements;
        });
      }


      document.documentElement.style.cursor = "crosshair"
      const postImageY = new Image()
      const postImageX = new Image()
      const postImageZLR = new Image()
      const postImageZRL = new Image()
      const postImageYLight = new Image()
      const postImageXLight = new Image()
      const postImageZLRLight = new Image()
      const postImageZRLLight = new Image()

      postImageY.src = post_y_icon
      postImageX.src = post_x_icon
      postImageZLR.src = post_z_lr_icon
      postImageZRL.src = post_z_rl_icon
      postImageYLight.src = post_y_light_icon
      postImageXLight.src = post_x_light_icon
      postImageZLRLight.src = post_z_lr_light_icon
      postImageZRLLight.src = post_z_rl_light_icon

      postImageY.filename = 'post_y_icon'
      postImageX.filename = 'post_x_icon'
      postImageZLR.filename = 'post_z_lr_icon'
      postImageZRL.filename = 'post_z_rl_icon'
      postImageYLight.filename = 'post_y_light_icon'
      postImageXLight.filename = 'post_x_light_icon'
      postImageZLRLight.filename = 'post_z_lr_light_icon'
      postImageZRLLight.filename = 'post_z_rl_light_icon'



    
      // GRABBING OF SPAN
      if(clickedLine){
        if(mouseMovement.axis == '') return
        setGrabbing(true)
        document.documentElement.style.cursor = "grabbing"
        const allLineConnected = []
        const betweenLines = []
        let removedPostsOnGrab = 0 

        let linesCopy = [...elements]
        let newConnectedLinePosts = postElements
        
        let linesConnectedInPost = elements.filter(item => ((ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.y1) === ceil(clickedLine.y1)) || (ceil(item.x1) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y2)) || (ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2))) && item.line !== clickedLine.line)
  
        const clickedIndex = elements.findIndex(item => ceil(item.x1) === ceil(clickedLine.x1) && ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y1) === ceil(clickedLine.y1) && ceil(item.y2) === ceil(clickedLine.y2))


        let updatedLine = {}
        
        allLineConnected.push(clickedLine.line)

        // ALLOW SPANS TO MOVE X/Y 

        let newX1 = clickedLine.x1;
        let newX2 = clickedLine.x2;

        let newY1 = clickedLine.y1;
        let newY2 = clickedLine.y2;

        const pointXDiff = Math.abs(grabPointX - pointX);
        const pointYDiff = Math.abs(grabPointY - pointY);
        
        if(mouseMovement.direction == 'up'){

          newY1 = clickedLine.y1 - pointYDiff;
          newY2 = clickedLine.y2 - pointYDiff;

          setgrabPointY(pointY)

        }else if(mouseMovement.direction == 'down'){

          newY1 = clickedLine.y1 + pointYDiff;
          newY2 = clickedLine.y2 + pointYDiff;

          setgrabPointY(pointY)

        }else if(mouseMovement.direction == 'left'){

          newX1 = clickedLine.x1 - pointXDiff;
          newX2 = clickedLine.x2 - pointXDiff;

          setgrabPointX(pointX)

        }else if(mouseMovement.direction == 'right'){

          newX1 = clickedLine.x1 + pointXDiff;
          newX2 = clickedLine.x2 + pointXDiff;  

          setgrabPointX(pointX)

        }

        const newX1Line = inLine(newX1, "x", canvas, cell, postImageSize)
        const newX2Line = inLine(newX2, "x", canvas, cell, postImageSize)
        const newY1Line = inLine(newY1, "y", canvas, cell, postImageSize)
        const newY2Line = inLine(newY2, "y", canvas, cell, postImageSize)

        newX1 = newX1Line + postImageSize / 2
        newX2 = newX2Line + postImageSize / 2
        newY1 = newY1Line + postImageSize / 2
        newY2 = newY2Line + postImageSize / 2

        if(clickedLine.hasOwnProperty("measurement")){
          updatedLine = createElement(newX1, newY1, newX2, newY2, clickedLine.line, clickedLine.group, stroke, '', clickedLine.measurement)
        }else{
          updatedLine = createElement(newX1, newY1, newX2, newY2, clickedLine.line, clickedLine.group, stroke)
        }
        

        const linesToBeRemoved = []        
       
        linesConnectedInPost.forEach(item => {
          
          const itemIndex = elements.findIndex(i => ceil(i.x1) === ceil(item.x1) && ceil(i.x2) === ceil(item.x2) && ceil(i.y1) === ceil(item.y1) && ceil(i.y2) === ceil(item.y2))

          let reverse = false

        
          if((ceil(item.x2) === ceil(clickedLine.x1) && ceil(item.y2) === ceil(clickedLine.y1)) || (ceil(item.x2) === ceil(clickedLine.x2) && ceil(item.y2) === ceil(clickedLine.y2))){
            reverse = true
          }
          
          let connectedLine = item
          
          if(ceil(item.x1) === ceil(item.x2) && ceil(item.y1) === ceil(item.y2)){
          
          }else{
            if (ceil(clickedLine.x2) === ceil(item.x1) && ceil(clickedLine.y2) === ceil(item.y1)) {
              // END of ClickedLine is connected to the START of Line A
              connectedLine = createElement(updatedLine.x2, updatedLine.y2, item.x2, item.y2, item.line, item.group, stroke)
            }else if (ceil(clickedLine.x1) === ceil(item.x1) && ceil(clickedLine.y1) === ceil(item.y1)) {
              // START of ClickedLine is connected to the START of Line A
              connectedLine = createElement(updatedLine.x1, updatedLine.y1, item.x2, item.y2, item.line, item.group, stroke)
            }else if (ceil(clickedLine.x2) === ceil(item.x2) && ceil(clickedLine.y2) === ceil(item.y2)) {
              // END of ClickedLine is connected to the END of Line A
              connectedLine = createElement(item.x1, item.y1, updatedLine.x2, updatedLine.y2, item.line, item.group, stroke)
            }else if (ceil(clickedLine.x1) === ceil(item.x2) && ceil(clickedLine.y1) === ceil(item.y2)) {
              // START of ClickedLine is connected to the END of Line A
              connectedLine = createElement(item.x1, item.y1, updatedLine.x1, updatedLine.y1, item.line, item.group, stroke)
            }
          }
       
          // THIS IS FOR MERGING OF SPANS WHEN DRAGGING
          if((ceil(updatedLine.y1) !== ceil(updatedLine.y2) && ceil(connectedLine.y1) !== ceil(connectedLine.y2)) && (ceil(updatedLine.x1) === ceil(connectedLine.x1) && ceil(updatedLine.x2) === ceil(connectedLine.x2)) && (isStraightLine(updatedLine) && isStraightLine(connectedLine))){
         
            if(ceil(updatedLine.y1) === ceil(connectedLine.y2) || ceil(updatedLine.y2) === ceil(connectedLine.y1) || ceil(updatedLine.y1) === ceil(connectedLine.y1) || ceil(updatedLine.y2) === ceil(connectedLine.y2)){
                const coord = [connectedLine.y1, connectedLine.y2, updatedLine.y1, updatedLine.y2]
                updatedLine.y1 = Math.min( ...coord )
                updatedLine.y2 = Math.max( ...coord )
                linesToBeRemoved.push(item.line)
            }
          }else if((ceil(updatedLine.x1) !== ceil(updatedLine.x2) && ceil(connectedLine.x1) !== ceil(connectedLine.x2)) && (ceil(updatedLine.y1) === ceil(connectedLine.y1) && ceil(updatedLine.y2) === ceil(connectedLine.y2)) && (isStraightLine(updatedLine) && isStraightLine(connectedLine))){
            if(ceil(updatedLine.x1) === ceil(connectedLine.x2) || ceil(updatedLine.x2) === ceil(connectedLine.x1) || ceil(updatedLine.x1) === ceil(connectedLine.x1) || ceil(updatedLine.x2) === ceil(connectedLine.x2)){
              
              const coord = [connectedLine.x1, connectedLine.x2, updatedLine.x1, updatedLine.x2]
              updatedLine.x1 = Math.min( ...coord )
              updatedLine.x2 = Math.max( ...coord )
              linesToBeRemoved.push(item.line)
            }
          }else{
            linesCopy[itemIndex] = connectedLine
            allLineConnected.push(item.line)
          }

          allLineConnected.push(connectedLine.line)

          // REMOVE POSTS IN BETWEEN SPANS
          newConnectedLinePosts = newConnectedLinePosts.filter(i => {
            if(i.line === item.line){
              
              if((ceil(i.x1) === ceil(item.x1 - postImageSize / 2) && ceil(i.y1) === ceil(item.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(item.x2 - postImageSize / 2) && ceil(i.y1) === ceil(item.y2 - postImageSize / 2))) {
                return true
              }else{
                removedPostsOnGrab++ 
                const findBetweenLine = elements.filter(e => ((ceil(i.x1) === ceil(e.x1 - postImageSize / 2) && ceil(i.y1) === ceil(e.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(e.x2 - postImageSize / 2) && ceil(i.y1) === ceil(e.y2 - postImageSize / 2))) && e.line === i.line)
                
                if(findBetweenLine.length > 0){
                  findBetweenLine.forEach(f => {
                    betweenLines.push(f.line)
                  })
                }        
                return false
              }
            }else if(i.line === clickedLine.line){
              if((ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2))){
                return true
              }else{
                removedPostsOnGrab++
                const findBetweenLine = elements.filter(e => ((ceil(i.x1) === ceil(e.x1 - postImageSize / 2) && ceil(i.y1) === ceil(e.y1 - postImageSize / 2)) || (ceil(i.x1) === ceil(e.x2 - postImageSize / 2) && ceil(i.y1) === ceil(e.y2 - postImageSize / 2))) && clickedLine.line === i.line)
                if(findBetweenLine.length > 0){
                  findBetweenLine.forEach(f => {
                    betweenLines.push(f.line)
                  })
                }
                
                return false
              }
            }
            return true
          })

        })
   
        linesCopy[clickedIndex] = updatedLine
        linesCopy = linesCopy.filter(v => !linesToBeRemoved.includes(v.line))
        
        newConnectedLinePosts.forEach((i, j) => {
          if(ceil(i.x1) === ceil(clickedLine.x1 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y1 - postImageSize / 2)) {
            newConnectedLinePosts[j].x1 = newX1Line;
            newConnectedLinePosts[j].y1 = newY1Line;
          }else if(ceil(i.x1) === ceil(clickedLine.x2 - postImageSize / 2) && ceil(i.y1) === ceil(clickedLine.y2 - postImageSize / 2)){
            newConnectedLinePosts[j].x1 = newX2Line;
            newConnectedLinePosts[j].y1 = newY2Line;
          }
        })
        
        const linesIncluded = []
        const postsExcluded = []

        linesCopy = linesCopy.filter(item => {
          if(item.x1 === item.x2 && item.y1 === item.y2){
            linesToBeRemoved.push(item.line)
            return false
          }
          linesIncluded.push(item.line)
          return true
        })

        const startConnected = linesCopy.find(v => (ceil(v.x1) === ceil(updatedLine.x1) && ceil(v.y1) === ceil(updatedLine.y1) || ceil(v.x2) === ceil(updatedLine.x1) && ceil(v.y2) === ceil(updatedLine.y1)) && v.line !== updatedLine.line)
        const endConnected = linesCopy.find(v => (ceil(v.x2) === ceil(updatedLine.x2) && ceil(v.y2) === ceil(updatedLine.y2) || ceil(v.x1) === ceil(updatedLine.x2) && ceil(v.y1) === ceil(updatedLine.y2)) && v.line !== updatedLine.line)

        const point = ceil(updatedLine.x1) === ceil(updatedLine.x2) ? updatedLine.x1 : updatedLine.y1
        const connectedToUpdatedLine = linesCopy.find(v => (((ceil(v.x1) === ceil(point) || ceil(v.x2) === ceil(point) && updatedLine.x1 === updatedLine.x2)) || ((ceil(v.y1) === ceil(point) || ceil(v.y2) === ceil(point)) && updatedLine.y1 === updatedLine.y2)) && v.line !== updatedLine.line)

        if(!startConnected && !endConnected){

          if(connectedToUpdatedLine){
            const connectedToThis = linesCopy.find(v => ((ceil(v.x1) === ceil(connectedToUpdatedLine.x1) && ceil(v.y1) === ceil(connectedToUpdatedLine.y1)) || (ceil(v.x2) === ceil(connectedToUpdatedLine.x1) && ceil(v.y2) === ceil(connectedToUpdatedLine.y1)) || (ceil(v.x1) === ceil(connectedToUpdatedLine.x2) && ceil(v.y1) === ceil(connectedToUpdatedLine.y2)) || (ceil(v.x2) === ceil(connectedToUpdatedLine.x2) && ceil(v.y2) === ceil(connectedToUpdatedLine.y2))) && v.line !== connectedToUpdatedLine.line)

            if(connectedToThis){
              if(ceil(updatedLine.x1) === ceil(updatedLine.x2)){
                updatedLine.y1 = connectedToThis.y1
                updatedLine.y2 = connectedToThis.y2
              }else{
                updatedLine.x1 = connectedToThis.x1
                updatedLine.x2 = connectedToThis.x2
              }
            }else{
              if(ceil(updatedLine.x1) === ceil(updatedLine.x2)){
                updatedLine.y1 = connectedToUpdatedLine.y1
              }else{
                updatedLine.x1 = connectedToUpdatedLine.x1
              }
            }
          }
        }
        
        newConnectedLinePosts = newConnectedLinePosts.filter(item => {
          if(!linesIncluded.includes(item.line)){
            return false
          }
          return true
        })
        const uniqueLines = linesCopy.filter((v,i,a)=>a.findIndex(t => (t.line === v.line)) === i)
        
        setPostElements(newConnectedLinePosts)
        setClickedLine(updatedLine)
        setElements(uniqueLines)
        setContinueProcess(false)
        setLinesConnectedOnGrab(allLineConnected)
        setLinesToBeRemoved(prevState => ([ ...prevState, ...betweenLines ]))
        if(resetPostsOnGrab){
          setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost - removedPostsOnGrab }))
          setResetPostsOnGrab(false)
        }

        return
      }
      // END OF GRABBING OF SPAN
      
      let index = elements.length - 1
      let postId = postElements.length
    
      let sameDirection = false

      let { x1, y1, x2, y2, line, group } = elements[index]

      

      if (dragSpan) {
        
        if(!canDragSpan && !dragHistory){
          setDrawing(false)
          return
        }
    
        document.documentElement.style.cursor = "grabbing"
        var originalVector = { x: dragHistory.line.x2 - dragHistory.line.x1, y: dragHistory.line.y2 - dragHistory.line.y1 };
  
        const dragLine = oppositeDirectionSpan ? canDragSpan.line : dragHistory.line
        const dragPost = oppositeDirectionSpan ? canDragSpan.post : dragHistory.post
       
        index = elements.findIndex(item => dragLine.line === item.line)

        x1 = dragLine.x1
        y1 = dragLine.y1
        x2 = dragLine.x2
        y2 = dragLine.y2
        line = dragLine.line
        group = dragLine.group
    
        let checkLineX = x1;
        let checkLineY = y1;

        if(ceil(dragHistory.post.x1 + postImageSize / 2) === ceil(dragHistory.line.x1) && ceil(dragHistory.post.y1 + postImageSize / 2) === ceil(dragHistory.line.y1)){
          checkLineX = x2;
          checkLineY = y2;
        }

        if(isStraightLine(dragHistory.line)){
          if(ceil(dragHistory.line.x1) !== ceil(dragHistory.line.x2)){
            if(ceil(dragHistory.line.y1 - postImageSize / 2) === ceil(pointY) && ceil(dragHistory.line.y2 - postImageSize / 2) === ceil(pointY)){
              sameDirection = true
            }
          }else{
            if(ceil(dragHistory.line.x1 - postImageSize / 2) === ceil(pointX) && ceil(dragHistory.line.x2 - postImageSize / 2) === ceil(pointX)){
              sameDirection = true
            }
          }
          
        }else{
          if (checkLineStraightness(originalVector, checkLineX, checkLineY, pointX, pointY)) {
            sameDirection = true
          }
        }
        
        const opositeX = ceil(x1 - postImageSize / 2) === ceil(dragPost.x1) ? x2 - postImageSize / 2 : x1 - postImageSize / 2
        const opositeY = ceil(y1 - postImageSize / 2) === ceil(dragPost.y1) ? y2 - postImageSize / 2 : y1 - postImageSize / 2

        const removePost = postElements.filter((item, i) => {
          if (ceil(item.x1) === ceil(opositeX) && ceil(item.y1) === ceil(opositeY) || !sameDirection) {
            return true
          }
          if ((item.line === line) || item.line === 'tmp') {
            return false
          }
          return true
        })
        setPostElements(removePost)
      }

      const centerOfPost = postImageSize / 2
      let postElementsCopy = [...postElements]
  
        pointX = pointX
        pointY = pointY


      let updatedElement = {}

      updatedElement = createElement(x1, y1, pointX + postImageSize / 2, pointY + postImageSize / 2, line, group, stroke)
      
      if (!oppositeDirectionSpan && canDragSpan && dragSpan && ceil(dragHistory.post.x1) === ceil(dragHistory.line.x1 - postImageSize / 2) && ceil(dragHistory.post.y1) === ceil(dragHistory.line.y1 - postImageSize / 2)) {
        updatedElement = createElement(x2, y2, pointX + postImageSize / 2, pointY + postImageSize / 2, line, group, stroke)
      }
   
      let elementsCopy = [...elements]

      if(!sameDirection && canDragSpan && !oppositeDirectionSpan){

        let foundIndex = false
        let ctr = 0
  
        while (!foundIndex) {
          const searchIndex = elementsCopy?.find(v => v.line === ctr)

          if(searchIndex){
            ctr++
          }else{
            foundIndex = true
            index = ctr
          }
        }

        if(ceil(canDragSpan.post.x1 + postImageSize / 2) === ceil(x1) && ceil(canDragSpan.post.y1 + postImageSize / 2) === ceil(y1)){
          updatedElement = createElement(canDragSpan.post.x1 + postImageSize / 2, canDragSpan.post.y1 + postImageSize / 2, x1, y1, index, group, stroke)
        }else{
          updatedElement = createElement(canDragSpan.post.x1 + postImageSize / 2, canDragSpan.post.y1 + postImageSize / 2, x2, y2, index, group, stroke)
        }
        
        elementsCopy.push(updatedElement)
        const history_index = elementsCopy.findIndex(item => dragHistory.line.line === item.line)
        elementsCopy[history_index] = dragHistory.line
        setElements(elementsCopy)

        setCanDragSpan({  
          "post": canDragSpan.post,
          "line": updatedElement
        })
        
        setOppositeDirectionSpan(true)
        
      }else{
        document.documentElement.style.cursor = "crosshair"
        if(sameDirection){
          document.documentElement.style.cursor = "grabbing"
          setOppositeDirectionSpan(false)
        
          if(elementsCopy.length > 0 && elementsHistory.length > 0 && dragSpan && dragHistory && (ceil(canDragSpan.line.x1) !== ceil(dragHistory.line.x1) || ceil(canDragSpan.line.x2) !== ceil(dragHistory.line.x2) || ceil(canDragSpan.line.y1) !== ceil(dragHistory.line.y1) || ceil(canDragSpan.line.y2) !== ceil(dragHistory.line.y2))){
            
            elementsCopy = elementsCopy.filter(item1 => elementsHistory[elementsHistory.length - 1]?.some(item2 => item2.line === item1.line));
          }
        }
        
        elementsCopy[index] = updatedElement

        const filteredElements = elementsCopy.filter(element => element !== null);
        setElements(filteredElements)
      }
      
      const relatedPosts = postElements.filter(item => item.line === 'tmp')

      let image_l = postImageXLight

      if (ceil(y1) === ceil(pointY + postImageSize / 2)) {
        image_l = postImageYLight
      } else if ((ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
        image_l = postImageZRLLight
      } else if ((ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
        image_l = postImageZLRLight
      }
      
      if (relatedPosts.length === 0) {
        const postElement = createPostElement(postId, image_l, pointX, pointY, postImageSize, postImageSize, "tmp")
        const positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
        if (!positionElement) {
          setPostElements(prevState => [...prevState, postElement])
        }
      } else {

        const updatePost = relatedPosts[0]
        updatePost.x1 = pointX
        updatePost.y1 = pointY
        
        postElementsCopy[postElements.length - 1] = updatePost

        let image = postImageX
        if (ceil(y1) === ceil(pointY + postImageSize / 2)) {
          image = postImageY
        } else if ((ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
          image = postImageZRL
        } else if ((ceil(x1) > ceil(pointX + postImageSize / 2) && ceil(y1) < ceil(pointY + postImageSize / 2)) || (ceil(x1) < ceil(pointX + postImageSize / 2) && ceil(y1) > ceil(pointY + postImageSize / 2))) {
          image = postImageZLR
        }

        postElementsCopy = postElementsCopy.map(item =>
          item.line === 'tmp'
            ? { ...item, image: image_l }
            : item
        )

        postElementsCopy = postElementsCopy.map(item =>
          item.line === ''
            ? { ...item, image: image }
            : item
        )

        setPostElements(postElementsCopy)
      }
      

    } else if (action === 'Gate') {

      if (clientX !== mouseDown.x || clientY !== mouseDown.y) {

        const line = findLine(clientX, clientY, elements, stroke)

        const gateWidth = theGateWidth
        const gateHeight = theGateHeight

        if (line.length > 0) {

          if (line[0].x1 !== line[0].x2 && line[0].y1 !== line[0].y2) {
            setCanAddGate(false)
            return
          }

          const existingGate = gateElements.filter(item => (clientX >= item.x1 && clientX <= item.x1 + item.x2 && (ceil(line[0].y1 - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 - gateOffset - (postImageSize * .25)) === ceil(item.y1) || ceil(line[0].y1 + gateOffset - (postImageSize * .25)) === ceil(item.y1))) || (clientY >= item.y1 && clientY <= item.y1 + item.y2 && (ceil(line[0].x1 - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 - gateOffset - (postImageSize * .25)) === ceil(item.x1) || ceil(line[0].x1 + gateOffset - (postImageSize * .25)) === ceil(item.x1))))

          if (existingGate.length > 0) {
            if(!isTouchDevice()){
              document.documentElement.style.cursor = "default"
            }
            setTempGate([])
            return
          }
          if(!isTouchDevice()){
            document.documentElement.style.cursor = "pointer"
          }
          setCanAddGate(true)
          const gateX_light = new Image()
          const gateY_light = new Image()

          gateX_light.src = gate_x_light
          gateY_light.src = gate_y_light

          const postId = gateElements.length

          const cursorX = clientX
          const cursorY = clientY

          let updatedX1 = cursorX
          let updatedY1 = cursorY

          let showGateSnap = true

          gateX_light.onload = () => {
            if (tempGate.length === 0) {

              let gateElement = {}

              if (line[0].x1 !== line[0].x2) {
                gateElement = createPostElement(postId, gateX_light, line[0].x1, line[0].y1 - (postImageSize * .25), gateWidth, gateHeight)
              } else {
                gateElement = createPostElement(postId, gateY_light, line[0].x1 - (postImageSize * .25), line[0].y1, gateHeight, gateWidth)
              }
              setTempGate([gateElement])
            } else {
              let updateGateElement = {}

              if (line[0].x1 !== line[0].x2) {
                if (line[0].x1 < line[0].x2) {
                  if (line[0].x2 - gateWidth < cursorX) {
                    updatedX1 = line[0].x2 - gateWidth
                  } else if (line[0].x1 < cursorX) {
                    updatedX1 = cursorX
                  } else {
                    updatedX1 = line[0].x1
                  }
                } else {
                  if (line[0].x1 - gateWidth < cursorX) {
                    updatedX1 = line[0].x1 - gateWidth
                  } else if (line[0].x2 < cursorX) {
                    updatedX1 = cursorX
                  } else {
                    updatedX1 = line[0].x2
                  }
                }

                let existingGateInPosts = postElements.filter(item => updatedX1 + item.x2 + (10 * percent) >= item.x1 && updatedX1 <= item.x1 + item.x2 && ceil(line[0].y1 - (postImageSize / 2)) === ceil(item.y1))
                
                if (existingGateInPosts.length > 0) {

                  if (line[0].x1 !== line[0].x2) {
                    if (line[0].x1 < line[0].x2) {
                      if (cursorX > existingGateInPosts[0].x1 - gateWidth && cursorX < existingGateInPosts[0].x1 + existingGateInPosts[0].x2 && cursorX > line[0].x1 + postImageSize && cursorX < line[0].x2 - postImageSize) {
                        if (cursorX <= existingGateInPosts[0].x1 + existingGateInPosts[0].x2
                          / 2) {
                          updatedX1 = existingGateInPosts[0].x1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedX1 = existingGateInPosts[0].x1 + existingGateInPosts[0].x2 - (postImageSize / 2)
                        }
                      } else if (line[0].x2 - line[0].x1 - gateWidth < cursorX && cursorX >= line[0].x2 - line[0].x1) {
                        updatedX1 = line[0].x1 + line[0].x2 - line[0].x1 - gateWidth
                      } else {
                        updatedX1 = line[0].x1
                      }
                    } else {
                      if (cursorX > existingGateInPosts[0].x1 - gateWidth && cursorX < existingGateInPosts[0].x1 + existingGateInPosts[0].x2 && cursorX > line[0].x2 + postImageSize && cursorX < line[0].x1 - postImageSize) {
                        if (cursorX <= existingGateInPosts[0].x1 + existingGateInPosts[0].x2 / 2) {
                          updatedX1 = existingGateInPosts[0].x1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedX1 = existingGateInPosts[0].x1 + existingGateInPosts[0].x2 - (postImageSize / 2)
                        }
                      } else if (line[0].x1 - line[0].x2 - gateWidth < cursorX && cursorX >= line[0].x1 - line[0].x2) {
                        updatedX1 = line[0].x2 + line[0].x1 - line[0].x2 - gateWidth
                      } else {
                        updatedX1 = line[0].x2
                      }
                    }
                  }
                }

                if (showGateSnap) {
                  updateGateElement = createPostElement(postId, gateX_light, updatedX1, line[0].y1 - (postImageSize * .25), gateWidth, gateHeight)
                }


              } else {
                if (line[0].y1 < line[0].y2) {
                  if (line[0].y2 - gateWidth < cursorY) {
                    updatedY1 = line[0].y2 - gateWidth
                  } else if (line[0].y1 < cursorY) {
                    updatedY1 = cursorY
                  } else {
                    updatedY1 = line[0].y1
                  }
                } else {
                  if (line[0].y1 - gateWidth < cursorY) {
                    updatedY1 = line[0].y1 - gateWidth
                  } else if (line[0].y2 < clientY) {
                    updatedY1 = cursorY
                  } else {
                    updatedY1 = line[0].y2
                  }
                }

                let existingGateInPosts = postElements.filter(item => updatedY1 + item.y2 + (10 * percent) >= item.y1 && updatedY1 <= item.y1 + item.y2 && ceil(line[0].x1 - (postImageSize / 2)) === ceil(item.x1))
                if (existingGateInPosts.length > 0) {

                  if (line[0].y1 !== line[0].y2) {
                    if (line[0].y1 < line[0].y2) {
                      if (cursorY > existingGateInPosts[0].y1 - gateWidth && cursorY < existingGateInPosts[0].y1 + existingGateInPosts[0].y2 && cursorY > line[0].y1 + postImageSize && cursorY < line[0].y2 - postImageSize) {
                        if (cursorY <= existingGateInPosts[0].y1 + existingGateInPosts[0].y2
                          / 2) {
                          updatedY1 = existingGateInPosts[0].y1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedY1 = existingGateInPosts[0].y1 + existingGateInPosts[0].y2 - (postImageSize / 2)
                        }
                      } else if (line[0].y2 - line[0].y1 - gateWidth < cursorY && cursorY >= line[0].y2 - line[0].y1) {
                        updatedY1 = line[0].y1 + line[0].y2 - line[0].y1 - gateWidth
                      } else {
                        updatedY1 = line[0].y1
                      }
                    } else {
                      if (cursorY > existingGateInPosts[0].y1 - gateWidth && cursorY < existingGateInPosts[0].y1 + existingGateInPosts[0].y2 && cursorY > line[0].y2 + postImageSize && cursorY < line[0].y1 - postImageSize) {
                        if (cursorY <= existingGateInPosts[0].y1 + existingGateInPosts[0].y2 / 2) {
                          updatedY1 = existingGateInPosts[0].y1 - gateWidth + (postImageSize / 2)
                        } else {
                          updatedY1 = existingGateInPosts[0].y1 + existingGateInPosts[0].y2 - (postImageSize / 2)
                        }
                      } else if (line[0].y1 - line[0].y2 - gateWidth < cursorY && cursorY >= line[0].y1 - line[0].y2) {
                        updatedY1 = line[0].y2 + line[0].y1 - line[0].y2 - gateWidth
                      } else {
                        updatedY1 = line[0].y2
                      }
                    }
                  }
                }

                if (showGateSnap) {
                  updateGateElement = createPostElement(postId, gateY_light, roundOff(line[0].x1 - (postImageSize * .25)), updatedY1, gateHeight, gateWidth)
                }

              }

              if (showGateSnap) {
                const copyTemp = [...tempGate]
                copyTemp[0] = updateGateElement
                setTempGate(copyTemp)
              }
            }
          }
        } else {
          if(!isTouchDevice()){
            document.documentElement.style.cursor = "default"
          }
          setTempGate([])
        }

      }
    }

  }

  const handleMouseUp = (event) => {
    event.stopPropagation();
    event.preventDefault()

    if(isTouchDevice() && !event.isPrimary){
      return
    }
    setDrawing(false);
    
    document.documentElement.style.cursor = "default"
    const postImageY = new Image()
    const postImageX = new Image()
    const postImageZLR = new Image()
    const postImageZRL = new Image()
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    postImageY.src = post_y_icon
    postImageX.src = post_x_icon
    postImageZLR.src = post_z_lr_icon
    postImageZRL.src = post_z_rl_icon

    postImageY.filename = 'post_y_icon'
    postImageX.filename = 'post_x_icon'
    postImageY.filename = 'post_y_icon'
    postImageX.filename = 'post_x_icon'
    postImageZLR.filename = 'post_z_lr_icon'
    postImageZRL.filename = 'post_z_rl_icon'

    if(grabbing){
      
      setGrabbing(false)

      let moved = true

      if(ceil(clickedLine.x1) !== ceil(clickedLine.x2) && clickedLineHistory){
        if(ceil(clickedLineHistory.y1) === ceil(clickedLine.y1) && ceil(clickedLineHistory.y2) === ceil(clickedLine.y2)){
          moved = false
        }
      }else{
        if(ceil(clickedLineHistory.x1) === ceil(clickedLine.x1) && ceil(clickedLineHistory.x2) === ceil(clickedLine.x2) ){
          moved = false
        }
      }

      const lines = linesConnectedOnGrab
      let elementsCopyA = [...elements]
      let postsCopyA = [...postElements]
      let gatessCopyA = [...gateElements]
      

      const betweenPosts = []
      let totalBetweenPostsOnGrab = 0

      let railCopy = itemList.rails.length ? [...itemList.rails] : [] 
      const lineLines = []
      const movedLines = []
      
      const removeNotCorner = []

      postsCopyA.forEach((item, index) => {
        if(item.line === clickedLineHistory.line){
          const checkIfCorner = elementsCopyA.find(v => (ceil(v.x1 - postImageSize / 2) === ceil(item.x1) && ceil(v.y1 - postImageSize / 2) === ceil(item.y1)) || (ceil(v.x2 - postImageSize / 2) === ceil(item.x1) && ceil(v.y2 - postImageSize / 2) === ceil(item.y1)))
          if(!checkIfCorner){
            removeNotCorner.push(index)
          }
        }
      })

      postsCopyA = postsCopyA.filter((v, i) => !removeNotCorner.includes(i))

      elementsCopyA.forEach((item, index) => {
        lineLines.push(item.line)
        if (lines.some(v => item.line === v)) {
          movedLines.push(item.line)
          elementsCopyA[index]['roughElement'].options.stroke = '#03658c'
          let totalLength = Math.sqrt(Math.pow(Math.abs(item.x2 - item.x1), 2) + Math.pow(Math.abs(item.y2 - item.y1), 2))
          if(item.measurement !== ''){
            totalLength = item.measurement * (canvas.width / cell / 1000)
          }
          const actualLength = roundToTwoDigits(totalLength / (canvas.width / cell))
          let betweenPostsCtr = 0

          if (actualLength > 3 && actualLength <= 6) {
            betweenPostsCtr = 1
          } else if (actualLength > 6 && actualLength % 3 === 0) {
            betweenPostsCtr = Math.floor(actualLength / 3) - 1
          } else if (actualLength > 6 && actualLength % 3 > 0) {
            betweenPostsCtr = Math.floor(actualLength / 3)
          }
          let incrementX = betweenPostsCtr !== 0 ? (Math.abs(item.x2 - item.x1)) / (betweenPostsCtr + 1) : 0
          let incrementY = betweenPostsCtr !== 0 ? (Math.abs(item.y2 - item.y1)) / (betweenPostsCtr + 1) : 0

          const image = item.x1 !== item.x2 ? postImageY : postImageX
          const postId = postElements.length

          let startX = item.x1
          let startY = item.y1

          for (let i = 0; i < betweenPostsCtr; i++) {
            let newX;
            let newY;

            if(item.x1 > item.x2){
              newX = startX - incrementX * (i + 1) - (postImageSize / 2)
            }else{
              newX = startX + incrementX * (i + 1) - (postImageSize / 2)
            }

            if(item.y1 > item.y2){
              newY = startY - incrementY * (i + 1) - (postImageSize / 2)
            }else{
              newY = startY + incrementY * (i + 1) - (postImageSize / 2)
            }

            let image = postImageX

            if (ceil(item.y1) === ceil(item.y2)) {
              image = postImageY
            } else if ((ceil(item.x1) < ceil(item.x2) && ceil(item.y1) < ceil(item.y2)) || (ceil(item.x1) > ceil(item.x2) && ceil(item.y1) > ceil(item.x2))) {
              image = postImageZRL
            } else if ((ceil(item.x1) > ceil(item.x2) && ceil(item.y1) < ceil(item.y2)) || (ceil(item.x1) < ceil(item.x2) && ceil(item.y1) > ceil(item.y2))) {
              image = postImageZLR
            }

            let postElement = createPostElement(postId + i, image, newX, newY, postImageSize, postImageSize, item.line)

            betweenPosts.push(postElement)
            totalBetweenPostsOnGrab++
          }

          if(item.line === clickedLineHistory.line){
            
            const startConnected = elementsCopyA.filter(v => (ceil(v.x1) === ceil(item.x1) && ceil(v.y1) === ceil(item.y1) || ceil(v.x2) === ceil(item.x1) && ceil(v.y2) === ceil(item.y1)) && v.line !== item.line)
            const endConnected = elementsCopyA.filter(v => (ceil(v.x2) === ceil(item.x2) && ceil(v.y2) === ceil(item.y2) || ceil(v.x1) === ceil(item.x2) && ceil(v.y1) === ceil(item.y2)) && v.line !== item.line)

            const existingPostStart = postsCopyA.filter(v => ceil(v.x1) === ceil(item.x1 - postImageSize / 2) && ceil(v.y1) === ceil(item.y1 - postImageSize / 2))
            const existingPostEnd = postsCopyA.filter(v => ceil(v.x1) === ceil(item.x2 - postImageSize / 2) && ceil(v.y1) === ceil(item.y2 - postImageSize / 2))

            if(startConnected.length === 0){
              if(existingPostStart.length === 0){
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, image, item.x1 - postImageSize / 2, item.y1 - postImageSize / 2, postImageSize, postImageSize, item.line)
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
                
              }else{
                
                const postIndex = postsCopyA.findIndex(v => ceil(v.x1) === ceil(item.x1 - postImageSize / 2) && ceil(v.y1) === ceil(item.y1 - postImageSize / 2))
                if(postIndex > -1){
                  postsCopyA[postIndex].image = image
                  postsCopyA[postIndex].direction = ''
                }
              
              }
            }else{
              if(existingPostStart.length === 0){
                const dir = getDirection(item.x1, item.y1, item.x2, item.y2, startConnected[0])
                
                let cImage = postImageY
                if (dir === 'Y') {
                  cImage = postImageX
                } else if (dir === "RL") {
                  cImage = postImageZLR
                } else if (dir === "LR") {
                  cImage = postImageZRL
                }
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, cImage, item.x1 - postImageSize / 2, item.y1 - postImageSize / 2, postImageSize, postImageSize, item.line, '', 'corner')
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
              }
              
            }
            
            if(endConnected.length === 0){
              if(existingPostEnd.length === 0){
                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, image, item.x2 - postImageSize / 2, item.y2 - postImageSize / 2, postImageSize, postImageSize, item.line)
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
                
              }else{
                const postIndex = postsCopyA.findIndex(v => ceil(v.x1) === ceil(item.x2 - postImageSize / 2) && ceil(v.y1) === ceil(item.y2 - postImageSize / 2))
                if(postIndex > -1){
                  postsCopyA[postIndex].image = image
                  postsCopyA[postIndex].direction = ''
                }                
              }
              
            }else{
              if(existingPostEnd.length === 0){
                const dir = getDirection(item.x1, item.y1, item.x2, item.y2, endConnected[0])
    
                let cImage = postImageY
                if (dir === 'Y') {
                  cImage = postImageX
                } else if (dir === "RL") {
                  cImage = postImageZLR
                } else if (dir === "LR") {
                  cImage = postImageZRL
                }

                const postElement = createPostElement(totalBetweenPostsOnGrab + 1, cImage, item.x2 - postImageSize / 2, item.y2 - postImageSize / 2, postImageSize, postImageSize, item.line, '', 'corner')
                betweenPosts.push(postElement)
                totalBetweenPostsOnGrab++
              }
              
            }
          }

          railCopy.forEach((v, i) => {
            if(v.line === item.line){
              railCopy[i].distance = actualLength * 1000
              railCopy[i].count = betweenPostsCtr + 1
            }
          })

        }
      })    
      
      let allPostsA = [...postsCopyA, ...betweenPosts]
      
      if(moved && linesToBeRemoved.length > 0){

        elementsCopyA = elementsCopyA.filter(item => !linesToBeRemoved.includes(item.line))

        allPostsA = allPostsA.filter(item => !linesToBeRemoved.includes(item.line))
        railCopy = railCopy.filter(item => !linesToBeRemoved.includes(item.line))
        
      }

      if(moved){
        gatessCopyA = gatessCopyA.filter(item => !movedLines.includes(item.line) && lineLines.includes(item.line))
        allPostsA = allPostsA.filter((v,i,a)=>a.findIndex(t => (ceil(t.x1) === ceil(v.x1) && ceil(t.y1) === ceil(v.y1))) === i)
        railCopy = railCopy.filter(item => lineLines.includes(item.line))

        allPostsA.forEach((e, i) => {
          if(e.direction === ''){
            const refixPost = elementsCopyA.filter(v => (ceil(v.x1 - postImageSize / 2) === ceil(e.x1) && ceil(v.y1 - postImageSize / 2) === ceil(e.y1)) || (ceil(v.x2 - postImageSize / 2) === ceil(e.x1) && ceil(v.y2 - postImageSize / 2) === ceil(e.y1)))
       
            if(refixPost.length > 1){
              const dir = getDirection(refixPost[0].x1, refixPost[0].y1, refixPost[0].x2, refixPost[0].y2, refixPost[1])
              let cImage = postImageY
              let cImageDirection = ''
              if (dir === 'Y') {
                cImage = postImageX
              } else if (dir === "LR") {
                cImage = postImageZLR
                cImageDirection = 'corner'
              } else if (dir === "RL") {
                cImage = postImageZRL
                cImageDirection = 'corner'
              }

              allPostsA[i].image = cImage
              allPostsA[i].direction = cImageDirection
            }
          }
        })        
      }
      

      const totalCornerPosts = cornerPostCounter(elementsCopyA)
      const totalStraightPosts = straightPostCounter(allPostsA, totalCornerPosts)
      const railActualLengths = getActualLengths(canvas, cell, elementsCopyA)
      const rail = cutRails(railActualLengths, railLength)
      const totalRail = getTotalRail(railActualLengths)
      const offAxisRail = countAllOffAxis(elementsCopyA, allPostsA, postImageSize, stroke)
      const straightAxisRail = countAllStraightAxis(elementsCopyA, allPostsA, postImageSize, stroke)
      const angleBracket = count90DegreeLines(elementsCopyA)

      setElements(elementsCopyA)
      setPostElements(allPostsA)
      setGateElements(gatessCopyA)
      setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy, rail: rail, totalRail: totalRail, offAxisRail: offAxisRail, straightAxisRail: straightAxisRail, angleBracket: angleBracket }))
      setLinesConnectedOnGrab([])
      return
      
    }else if(!grabbing && onLine){
      return
    }

    if ((inputLength && lastDistance === null) || !secondClick || elements.length === 0 || !continueProcess) {
      focusInputField()
      setSecondClick(true)
      return
    }
    setClickedLine(null)

    const pos = getMousePos(canvas, event);

    const clientX = pos.x
    const clientY = pos.y
    let pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    let pointY = inLine(clientY, "y", canvas, cell, postImageSize)
    let ogPointX = inLine(clientX, "x", canvas, cell, postImageSize)
    let ogPointY = inLine(clientY, "y", canvas, cell, postImageSize)

    const index = !oppositeDirectionSpan && canDragSpan && dragSpan ? elements.findIndex(item => item.line === dragHistory.line.line) : elements.length - 1

    const { x1, x2, y1, y2, line, group } = elements[index]
    const centerOfPost = postImageSize / 2

    if (Math.abs((pointX + postImageSize / 2) - x1) < Math.abs((pointY + postImageSize / 2) - y1)) {
      pointX = x1 - centerOfPost
      pointY = pointY
    } else {
      pointX = pointX
      pointY = y1 - centerOfPost
    }
    
    if (action === 'Post') {
      if((ceil(pointX) !== ceil(x1 - postImageSize / 2) && ceil(pointX) !== ceil(x2 - postImageSize / 2)) || (ceil(pointY) !== ceil(y1 - postImageSize / 2) && ceil(pointY) !== ceil(y2 - postImageSize / 2))){
        return
      }
      let positionElement = getElementAtPosition(pointX, pointY, baseBox, postElements)
      if (!positionElement && postElements.length > 0) {
        const lastPost = postElements[postElements.length - 1]
        if (lastPost.line === 'tmp') {
          positionElement = lastPost
        }
      }

      if (positionElement) {
       
        if (elements.length > 0) {

          setShowCheckOut(true)

          const elementsCopy = [...elements]
       
          const existingElement = elements.filter(item => ceil(item.x1) === ceil(x1) && ceil(item.y1) === ceil(y1) && ceil(item.x2) === ceil(x2) && ceil(item.y2) === ceil(y2) && item.line !== line)
    
          const zeroDistance = Math.abs(x2 - x1) === 0 && Math.abs(y2 - y1) === 0
          if (zeroDistance || existingElement.length > 0) {
       
            let removeLast = elementsCopy
            let removeLastPost = [...postElements]
      
            removeLast = removeLast.filter(item => item.line !== elements[index].line)
          
            removeLastPost = removeLastPost.filter(item => item.line !== elements[index].line && item.line !== '' && item.line !== 'tmp')
         
            if(zeroDistance && removeLast.length > 0 && removeLastPost.length > 0){
              
              const existingPostIndex = removeLastPost.findIndex(item => ceil(item.x1) === ceil(elements[index].x1 - postImageSize / 2) && ceil(item.y1) === ceil(elements[index].y1 - postImageSize / 2))
              const existineLine = removeLast.find(item => (ceil(item.x1) === ceil(elements[index].x1) && ceil(item.y1) === ceil(elements[index].y1) || ceil(item.x2) === ceil(elements[index].x1) && ceil(item.y2) === ceil(elements[index].y1)))
              let image = ''
              if(existineLine){
                image = existineLine.x1 !== existineLine.x2 ? postImageY : postImageX
              }
              if(existingPostIndex > -1){
                
                if(existineLine){
                  removeLastPost[existingPostIndex].image = image
                  removeLastPost[existingPostIndex].direction = ''
                  removeLastPost[existingPostIndex].line = existineLine.line
                }
              }else{
                
                if(existineLine){
                  const postElement = createPostElement(removeLastPost.length + 1, image, elements[index].x1 - postImageSize / 2, elements[index].y1 - postImageSize / 2, postImageSize, postImageSize, existineLine.line)
                  removeLastPost.push(postElement)
                }
              }
            }

            setElements(removeLast)
            setPostElements(removeLastPost)

            let railCopy = itemList.rails.length ? [...itemList.rails] : []

            const totalCornerPosts = cornerPostCounter(removeLast)
            const totalStraightPosts = straightPostCounter(removeLastPost, totalCornerPosts)
            const railActualLengths = getActualLengths(canvas, cell, removeLast)
            const rail = cutRails(railActualLengths, railLength)
            const totalRail = getTotalRail(railActualLengths)
            const offAxisRail = countAllOffAxis(removeLast, removeLastPost, postImageSize, stroke)
            const straightAxisRail = countAllStraightAxis(removeLast, removeLastPost, postImageSize, stroke)
            const angleBracket = count90DegreeLines(removeLast)

            setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy, rail: rail, totalRail: totalRail, offAxisRail: offAxisRail, straightAxisRail: straightAxisRail, angleBracket: angleBracket }))
            setConnectedLine(null)
            return
          }

          const lastEl = elementsCopy[elementsCopy.length - 1]

          setLastRailInput(lastRailInput + 1)
            

          let reverse = !oppositeDirectionSpan && canDragSpan && dragSpan && ceil(canDragSpan.post.x1) === ceil(canDragSpan.line.x1 - postImageSize / 2) && ceil(canDragSpan.post.y1) === ceil(canDragSpan.line.y1 - postImageSize / 2) ? true : false
    

          if (reverse) {
            if(x1 !== x2){
              pointX = x1 - centerOfPost
              pointY = pointY
            }else{
              pointX = pointX
              pointY = y1 - centerOfPost
            }
          } else {
            if(x1 !== x2){
              pointX = x2 - centerOfPost
              pointY = pointY
            }else{
              pointX = pointX
              pointY = y2 - centerOfPost
            }
          }

          let postElementsCopy = [...postElements]
          // isStraightLine(lastEl) && 

          let startX = !reverse ? x1 : pointX + centerOfPost
          let endX = isStraightLine(lastEl) && !reverse ? positionElement.x1 + centerOfPost : x2
          let startY = !reverse ? y1 : pointY + centerOfPost
          let endY = isStraightLine(lastEl) && !reverse ? positionElement.y1 + centerOfPost : y2

          let removePostLine = 'x'
      
          let showManualInput = true;

          const updatedElement = createElement(startX, startY, endX, endY, line, group, stroke)

          const start_connected = elements.filter(item => ((ceil(item.x1) === ceil(updatedElement.x1) && ceil(item.y1) === ceil(updatedElement.y1)) || (ceil(item.x2) === ceil(updatedElement.x1) && ceil(item.y2) === ceil(updatedElement.y1))) && item.line !== updatedElement.line)
          const end_connected = elements.filter(item => ((ceil(item.x2) === ceil(updatedElement.x2) && ceil(item.y2) === ceil(updatedElement.y2)) || (ceil(item.x1) === ceil(updatedElement.x2) && ceil(item.y1) === ceil(updatedElement.y2))) && item.line !== updatedElement.line)
          if(start_connected.length > 0 && end_connected.length > 0){
            showManualInput = false;
            if(end_connected[0].measurement !== '' && start_connected[0].measurement !== '' && end_connected[0].measurement === start_connected[0].measurement){
              updatedElement['measurement'] = end_connected[0].measurement;
            }
          }

          setShowUndo(true)

          elementsCopy[index] = updatedElement
          elementsCopy[index]['roughElement'].options.stroke = '#03658c'
          const finalElements = elementsCopy.filter(v => v.line !== removePostLine)
          
          setElements(finalElements)
 

          if(canDragSpan && dragSpan){
            const reGate = gateElements.filter(item => item.line !== lastEl.line)
            setGateElements(reGate)
          }
          
          if(oppositeDirectionSpan && canDragSpan && dragSpan){
            postElementsCopy = [...postElementsCopy, ...dragPostHistory]
          }
          postImageY.onload = () => {
            const dragPost = dragHistory && dragHistory.post ? dragHistory.post : null;
   
            if(dragPost && ((ceil(dragPost.x1) === ceil(ogPointX) && ceil(dragPost.y1) === ceil(ogPointY)) || (ceil(dragPost.x1) === ceil(endX - centerOfPost) && ceil(dragPost.y1) === ceil(endY - centerOfPost)))){
          
            }else{
              if (connectedLine && elements.length >= 2) {
     
                const con = oppositeDirectionSpan && dragSpan ? dragHistory.line : connectedLine[0]
                const dir = getDirection(startX, startY, endX, endY, con)
  
                let cImage = postImageY
                let cImageDirection = ''
                if (dir === 'Y') {
                  cImage = postImageX
                } else if (dir === "LR") {
                  cImage = postImageZLR
                  cImageDirection = 'corner'
                } else if (dir === "RL") {
                  cImage = postImageZRL
                  cImageDirection = 'corner'
                }
        
                postElementsCopy.forEach((item, index) => {
                  const constIsConnected = finalElements.filter(el => (ceil(item.x1) === ceil(el.x1 - centerOfPost) && ceil(item.y1) === ceil(el.y1 - centerOfPost) || ceil(item.x1) === ceil(el.x2 - centerOfPost) && ceil(item.y1) === ceil(el.y2 - centerOfPost) && item.line !== 'tmp'))
                  if(constIsConnected.length > 1){
  
                    if ((ceil(item.x1) === ceil(startX - centerOfPost) && ceil(item.y1) === ceil(startY - centerOfPost)) || (ceil(item.x1) === ceil(endX - centerOfPost) && ceil(item.y1) === ceil(endY - centerOfPost))) {
                      postElementsCopy[index].image = cImage
                      postElementsCopy[index].direction = cImageDirection

                    }
                    
                    if (ceil(item.x1) === ceil(endX - centerOfPost) && ceil(item.y1) === ceil(endY - centerOfPost) && item.line !== 'tmp' && isStraightLine({x1: startX, y1: startY, x2: endX, y2: endY})) {
                      console.log("dir", dir)
                      postElementsCopy[index].image = dir === "LR" ? postImageZRL : postImageZLR
                    
                      postElementsCopy[index].direction = cImageDirection
                    }
                
                  }

                })
              }
  
            }
            
            const relatedPosts = postElements.filter(item => item.line === 'tmp')
            let actualLength = 0;
            if (relatedPosts.length > 0) {
              
              const updatePost = relatedPosts[0]

              updatePost.image = postImageY

              postElementsCopy[postElements.length - 1] = updatePost

              let totalLength = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2))
              if(updatedElement.measurement !== ''){
                totalLength = updatedElement.measurement * (canvas.width / cell / 1000)
              }

              actualLength = roundToTwoDigits(totalLength / (canvas.width / cell))

              let betweenPostsCtr = 0
              if (actualLength > 3 && actualLength <= 6) {
                betweenPostsCtr = 1
              } else if (actualLength > 6 && actualLength % 3 === 0) {
                betweenPostsCtr = Math.floor(actualLength / 3) - 1
              } else if (actualLength > 6 && actualLength % 3 > 0) {
                betweenPostsCtr = Math.floor(actualLength / 3)
              }
        
              let incrementX = betweenPostsCtr !== 0 ? (endX - startX) / (betweenPostsCtr + 1) : 0
              let incrementY = betweenPostsCtr !== 0 ? (endY - startY) / (betweenPostsCtr + 1) : 0

              let betweenPosts = []
              const index = !oppositeDirectionSpan && canDragSpan && dragSpan ? elements.findIndex(v => v.line === dragHistory.line.line) : elements.length - 1
              const lineNumber = elements[index].line
              const postId = postElements.length

              let image = postImageX
              let imageDirection = ''
              if (ceil(startY) === ceil(endY)) {
                image = postImageY
              } else if ((ceil(startX) < ceil(endX) && ceil(startY) < ceil(endY)) || (ceil(startX) > ceil(endX) && ceil(startY) > ceil(endY))) {
                image = postImageZRL
              } else if ((ceil(startX) > ceil(endX) && ceil(startY) < ceil(endY)) || (ceil(startX) < ceil(endX) && ceil(startY) > ceil(endY))) {
                image = postImageZLR
              }
           
              for (let i = 0; i < betweenPostsCtr; i++) {
                const postElement = createPostElement(postId, image, startX + incrementX * (i + 1) - (postImageSize / 2), startY + incrementY * (i + 1) - (postImageSize / 2), postImageSize, postImageSize, lineNumber, '', imageDirection)
                betweenPosts.push(postElement)
              }

              const postExist = postElementsCopy.filter(item => ceil(item.x1) === ceil(endX - postImageSize / 2) && ceil(item.y1) === ceil(endY - postImageSize / 2))

              if (postExist.length > 1 || removePostLine !== 'x') {
                const postExistIndex = postElementsCopy.findIndex(item => item.line === postExist[0].line && ceil(item.x1) === ceil(postExist[0].x1) && ceil(postExist[0].y1) === ceil(postExist[0].y1))
                if(oppositeDirectionSpan){
                  postElementsCopy[postExistIndex].direction = 'corner'
                }
                
                setCanAdjustSpan(false)
                postElementsCopy = postElementsCopy.filter(item => item.line !== 'tmp')
                
              } else {
                setCanAdjustSpan(true)
                postElementsCopy = postElementsCopy.map(item =>
                  item.line === 'tmp'
                    ? { ...item, image: image }
                    : item
                )
              }

              postElementsCopy = postElementsCopy.map(item =>
                item.line === 'tmp' || item.line === ''
                  ? { ...item, line: lineNumber }
                  : item
              )
              let allPosts = [...postElementsCopy, ...betweenPosts]

              const linesLine = []
              finalElements.forEach(v => {
                linesLine.push(v.line)
              })
              
              allPosts = allPosts.filter(v => linesLine.includes(v.line))

              allPosts = allPosts.filter((v,i,a) => a.findIndex(t => (ceil(t.x1) === ceil(v.x1) && ceil(t.y1) === ceil(v.y1))) === i) 
              
              setPostElements(allPosts)

              let lineCtr = elements.length === 1 || (!oppositeDirectionSpan && canDragSpan && dragSpan) ? betweenPostsCtr + 2 : betweenPostsCtr

              const allPostLine = allPosts.filter(item => item.line === lineNumber)

              if(!oppositeDirectionSpan && canDragSpan && dragSpan && betweenPostsCtr + 1 === allPostLine.length){
                lineCtr = lineCtr - 1
              }

              let linePostsCtr = lineCtr
            
              if(!oppositeDirectionSpan && canDragSpan && dragSpan){
                linePostsCtr = linePostsCtr - removedPosts
              }

              let railCopy = itemList.rails.length ? [...itemList.rails] : []
              railCopy = railCopy.filter(v => linesLine.includes(v.line))
              const foundRail = railCopy.find(item => item.line === lineNumber)
              const totalCornerPosts = cornerPostCounter(finalElements)
              const totalStraightPosts = straightPostCounter(allPosts, totalCornerPosts)
              const railActualLengths = getActualLengths(canvas, cell, finalElements)
              const rail = cutRails(railActualLengths, railLength)
              const totalRail = getTotalRail(railActualLengths)
              const offAxisRail = countAllOffAxis(finalElements, allPosts, postImageSize, stroke)
              const straightAxisRail = countAllStraightAxis(finalElements, allPosts, postImageSize, stroke)
              const angleBracket = count90DegreeLines(finalElements)
            
              if (!foundRail) {
                railCopy[railCopy.length] = { "count": railCopy.length === 0 ? linePostsCtr - 1 : linePostsCtr + 1, "distance": parseFloat(actualLength * 1000), "line": lineNumber }
  
                setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: railCopy, rail: rail, totalRail: totalRail, offAxisRail: offAxisRail, straightAxisRail: straightAxisRail, angleBracket: angleBracket }))

              } else {
  
                const rails = railCopy.map(item =>
                  item.line === lineNumber
                    ? { ...item, count: betweenPostsCtr + 1 === allPostLine.length ? lineCtr : lineCtr - 1, distance: parseFloat(actualLength * 1000) }
                    : item
                )
                setItemList(prevState => ({ ...prevState, straightPost: totalStraightPosts, cornerPost: totalCornerPosts, rails: rails, rail: rail, totalRail: totalRail, offAxisRail: offAxisRail, straightAxisRail: straightAxisRail, angleBracket: angleBracket }))
     
              }
            }
            // FOR AUTO POPUP OF MANUAL INPUT
            if(showManualInput){

              setCurrentEvent({ "clientX": clientX, "clientY": clientY })
              let totalLength = Math.sqrt(Math.pow(updatedElement.x2 - updatedElement.x1, 2) + Math.pow(updatedElement.y2 - updatedElement.y1, 2))
 
              if(updatedElement.measurement){
                totalLength = updatedElement.measurement * (canvas.width / cell / 1000)
              }

              const actualLength = (Math.round((totalLength / (canvas.width / cell) * 1000) * 100) / 100)
              
              setManualInputValue(Math.floor(actualLength))

              setInputLength(true)
              setClickedLine(updatedElement)
            }
          }
        }
        setConnectedLine(null)
      }
      setRemovedPosts(0)
    }else if(action === 'Gate'){
      if (tempGate.length > 0 && canAddGate) {
        handleMouseDown(event)
      }
    }
  }

  const handleDoubleClick = (event) => {
    
    const pos = getMousePos(canvas, event, true);

    const clientX = pos.x
    const clientY = pos.y

    const pointX = inLine(clientX, "x", canvas, cell, postImageSize)
    const pointY = inLine(clientY, "y", canvas, cell, postImageSize)

    if ((pointX - canvas.width / cell + postImageSize / 2 < 0) || (canvas.width - (pointX + postImageSize / 2) < canvas.width / cell) || (pointY - canvas.width / cell + postImageSize / 2 < 0)) return

    const line = findLine(clientX, clientY, elements, stroke)


    if (action === 'Post') {
      setHasDoubleClicked(true)
      const inPost = findInPost(clientX, clientY, postElements, elements, postImageSize)

      if (line.length === 0 && inPost.length === 0 && elements.length > 0) {
        setContinueProcess(false)
        return
      }

      const clickedPost = postElements.find(post => 
        clientX >= post.x1 - (post.x2 * 0.375) && clientX <= post.x1 + (post.x2 * 0.75) &&
        clientY >= post.y1 - (post.y2 * 0.375) && clientY <= post.y1 + (post.y2 * 0.75)
      );

      if(clickedPost){
        setDrawing(false);
        setInputLength(false);
        setConnectedLine(null);
        setSecondClick(true);
        setClickedLine(null);
        const currentColorIndex = colorOptions.indexOf(clickedPost.color);
        const newColorIndex = (currentColorIndex + 1) % colorOptions.length;
        const newColor = colorOptions[newColorIndex];
        const postElementsCopy = [...postElements];

        const updatedPosts = postElementsCopy.map(post => {
          if(post.x1 === clickedPost.x1 && post.y1 === clickedPost.y1){
            
            const filenameBase = getSubstringIncludingIcon(post.image.filename);
                      
            const newImageKey = newColor !== 'blue' ? `${filenameBase}_${newColor}` : filenameBase;
          
            const updatedImage = dynamicPostIcons[newImageKey] ? dynamicPostIcons[newImageKey] : post.image;
            return { ...post, color: newColor, image: updatedImage }
          }
          return post
        });
        
        setPostElements(updatedPosts);
        return
      }
    
      const start_connected = elements.filter(item => ((ceil(item.x1) === ceil(line[0].x1) && ceil(item.y1) === ceil(line[0].y1)) || (ceil(item.x2) === ceil(line[0].x1) && ceil(item.y2) === ceil(line[0].y1))) && item.line !== line[0].line)
      const end_connected = elements.filter(item => ((ceil(item.x2) === ceil(line[0].x2) && ceil(item.y2) === ceil(line[0].y2)) || (ceil(item.x1) === ceil(line[0].x2) && ceil(item.y1) === ceil(line[0].y2))) && item.line !== line[0].line)

      if(end_connected.length !== 0 && start_connected.length !== 0){
        setContinueProcess(false)
        return
      }

      setClickedLine(null);
      setContinueProcess(true);
      
      if (line.length > 0 && inPost.length === 0) {
          setCurrentEvent({ "clientX": clientX, "clientY": clientY })
          let totalLength = Math.sqrt(Math.pow(line[0].x2 - line[0].x1, 2) + Math.pow(line[0].y2 - line[0].y1, 2))
   
          if(line[0].measurement){
            totalLength = line[0].measurement * (canvas.width / cell / 1000)
          }

          const actualLength = (Math.round((totalLength / (canvas.width / cell) * 1000) * 100) / 100)
          
          setManualInputValue(Math.floor(actualLength))
          
          setInputLength(true)
          setClickedLine(line[0])
          return
      }

    }

  }

  const handleZoomButtonHover = (e) => {
    const id = e.target.id
    if (id === 'minusButton') {
      e.target.src = minus
    } else if (id === 'plusButton') {
      e.target.src = plus
    }
  }

  const handleZoomButtonMouseOut = (e) => {
    const id = e.target.id
    if (id === 'minusButton') {
      e.target.src = minus_2
    } else if (id === 'plusButton') {
      e.target.src = plus_2
    }
  }

  const handleZoomButtonClick = (e) => {
    setPrevCell(cell)
    setZoomTrigger(true)
    setGateOnQueue(null)
    const id = e.target.id

    if (id === 'minusButton') {
      setCell(cell + 1 > maxZoomOut ? cell : cell + 1)
    } else if (id === 'plusButton') {
      setCell(cell - 1 < initialCell ? cell : cell - 1)
    }
  }

  const handleZoomWheel = (e) => {
    setGateOnQueue(null)
    if (e.deltaY !== 0) {
      setPrevCell(cell)
      setZoomTrigger(true)
      if (e.deltaY < 0) {
        setCell(cell + 1 > initialCell + 8 ? cell : cell + 1)
      } else if (e.deltaY > 0) {
        setCell(cell - 1 < initialCell ? cell : cell - 1)
      }
    }
  }

  const toggleAdjustGateButton = () => {
    setAdjustGate(adjustGate ? false : true)
    setFlipGate(false)
  }

  const toggleFlipGateButton = () => {
    setFlipGate(flipGate ? false : true)
    setAdjustGate(false)
  }

  const toggleModal = () => {
    if (!clickedModal) {

      const srcCanvas = document.getElementById("canvas")

      const destinationCanvas = document.createElement("canvas");
      destinationCanvas.width = srcCanvas.width;
      destinationCanvas.height = srcCanvas.height;

      const destCtx = destinationCanvas.getContext('2d');

      destCtx.fillStyle = "#f2f2f2";
      destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);

      destCtx.drawImage(srcCanvas, 0, 0);

      const dataURL = destinationCanvas.toDataURL();
      setCanvasImage(dataURL)

      formData['canvas'] = dataURL

    }
    setClickedModal('checkoutModal')
  }

  const openModal = (modal) => {
    setClickedModal(modal)
  }

  const closeModal = () => {
    setClickedModal(null)
  }

  const validateFormField = () => {
    const nameInput = formData.name;
    const emailInput = formData.email;
    const focusedElement = document.activeElement; 

    setSuccessFormSubmit("init"); 
  
    if (!nameInput || !emailInput) {
      setSuccessFormSubmit("error"); 
      if (focusedElement) {
        focusedElement.blur();
      }
      return false;
    }
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailInput)) {
      setSuccessFormSubmit("error");
      if (focusedElement) {
        focusedElement.blur();
      }
      return false;
    }
  
    return true;
  };

  const ecomRedirect = async (skipValidation, event) => {
    const focusedElement = document.activeElement; 
    const submitBtn = event.target;
    const color = formData.color;
  
    if (!skipValidation && !validateFormField()) {
      return;
    }

    if (!color) {
      setSuccessFormSubmit("error-color"); 
      if (focusedElement) {
        focusedElement.blur();
      }
      return;
    }

    submitBtn.innerHTML = 'Please wait';

    const buttons = document.querySelectorAll('.checkout-btn');
    buttons.forEach(button => {
      button.disabled = true;
      button.classList.add('disabled');
    });
    
    let redirectTo = generateEcomURL(products, itemList, formData.color);
  
    await fetch(process.env.REACT_APP_WC_URL + 'canvas.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(res => res.json())
    .then(
      (result) => {
        const filename = result.filename;
        redirectTo = redirectTo + '&canvas=' + filename;
      },
      (error) => {
        console.log(error);
      }
    );

    if (!skipValidation && process.env.REACT_APP_SEND_EMAIL_NOTIFICATION !== 'false') {
      await fetch(mailer_url, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(formData)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error === 0) {
            setSuccessFormSubmit("success");
            window.location.href = redirectTo;
          } else {
            setSuccessFormSubmit("error");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      window.location.href = redirectTo;
    }
  };

  const handleChangeFormInput = (event) => {
    const { name, value } = event.target;

    formData[name] = value;
  }

  const modalBody = <form>
    <div className="flex flex-col md:flex-col">
      <div className="w-full md:w-8/12 mx-auto h-300px md:h-auto">
        <img src={canvasImage} alt="canvas" className="h-full w-full object-contain" />
      </div>
      {/* <div className="w-full px-0 py-4 text-sm leading-loose whitespace-pre-line md:w-4/12 md:px-4">
        {(formData.items).join(" \n ")}
      </div> */}
      <br />
      <input type="text" className="w-full md:w-8/12 mx-auto px-2 py-1 mb-2 text-xs font-medium bg-white border outline-none h-9 focus:border-primary" required="required" placeholder="Name" name="name" onChange={handleChangeFormInput} />
      <input type="email" className="w-full md:w-8/12 mx-auto px-2 py-1 mb-2 text-xs font-medium bg-white border outline-none h-9 focus:border-primary" required="required" placeholder="Email" name="email" onChange={handleChangeFormInput} />
      <select className="w-full md:w-8/12 mx-auto px-2 py-1 text-xs font-medium bg-white border outline-none h-9 focus:border-primary" required="required" placeholder="Color" name="color" onChange={handleChangeFormInput} >
        <option value="">Select Color</option>
        <option value="monument">Monument</option>
        <option value="satinBlack">Satin Black</option>
        <option value="surfmist">Surfmist</option>
        <option value="white">White</option>
        <option value="custom">Custom</option>
      </select>
    </div>


    <div className="h-10">
      {/* <span className={`text-center text-xs text-green-400 block py-3 ${successFormSubmit === 'success' ? '' : 'hidden'}`}>You will now be redirected to cart page.</span> */}
      <span className={`text-center text-xs text-green-400 block py-3 ${successFormSubmit === 'error' ? '' : 'hidden'}`}>Please provide required details.</span>
      <span className={`text-center text-xs text-green-400 block py-3 ${successFormSubmit === 'error-color' ? '' : 'hidden'}`}>Please select the fence color.</span>
    </div>
    <div className='flex space-x-2 justify-center'>
      <OutlineButton type="button" classes="checkout-btn" text="Skip" show={true} buttonEvent={(event) => ecomRedirect(true, event)} />
      <PrimaryButton type="button" classes="checkout-btn" text="Next" show={true} buttonEvent={(event) => ecomRedirect(false, event)}/>
    </div>
  </form>;

  const handleRemoveGate = () => {
    const gate = gateOnQueue
    let removedGates = 0
    const removeGate = gateElements.filter(item => {
      if (item.x1 === gate.x1 && item.y1 === gate.y1) {
        removedGates++
        return false
      }
      return true
    })
    let removedPostsCtr = 0
    const removePosts = postElements.filter(item => {

      if((((ceil(item.x1) === ceil(gate.x1 - postImageSize / 2) || ceil(item.x1) === ceil(gate.x1 + gate.x2 - postImageSize / 2)) && (ceil(item.y1 + postImageSize * .25) === ceil(gate.y1) || ceil(item.y1 + postImageSize * .25) === ceil(gate.y1 + gateOffset))) || ((ceil(item.y1) === ceil(gate.y1 - postImageSize / 2) || ceil(item.y1) === ceil(gate.y1 + gate.y2 - postImageSize / 2)) && (ceil(item.x1 + postImageSize * .25) === ceil(gate.x1) || ceil(item.x1 + postImageSize * .25) === ceil(gate.x1 + gateOffset)))) && item.postId === 'gate_post'){
        removedPostsCtr++
        return false
      }
      return true
    })

    setItemList(prevState => ({ ...prevState, straightPost: prevState.straightPost - removedPostsCtr, gates: prevState.gates - removedGates }))

    setPostElements(removePosts)
    setGateElements(removeGate)

    if (removeGate.length === 0) {
      setAdjustGate(false)
    }

    setGateOnQueue(null)
  }

  const handleFlipGate = () => {
    const gateX = new Image()
    const gateY = new Image()
    const gateXF = new Image()
    const gateXFA = new Image()
    const gateXFB = new Image()
    const gateYF = new Image()
    const gateYFA = new Image()
    const gateYFB = new Image()

    gateX.src = gate_x
    gateY.src = gate_y
    gateXF.src = gate_x_f
    gateXFA.src = gate_x_fa
    gateXFB.src = gate_x_fb
    gateYF.src = gate_y_f
    gateYFA.src = gate_y_fa
    gateYFB.src = gate_y_fb

    gateX.filename = 'gate_x'
    gateY.filename = 'gate_y'
    gateXF.filename = 'gate_x_f'
    gateXFA.filename = 'gate_x_fa'
    gateXFB.filename = 'gate_x_fb'
    gateYF.filename = 'gate_y_f'
    gateYFA.filename = 'gate_y_fa'
    gateYFB.filename = 'gate_y_fb'


    const imagesX = [gateX, gateXF, gateXFA, gateXFB]
    const imagesY = [gateY, gateYF, gateYFA, gateYFB]


    const gate = gateOnQueue
    const gate_copy = gate

    let type = gate.image_type
    gateXF.onload = () => {
      if (type === '') {
        type = 0
      }
      type++

      if (type === 4) {
        type = 0
      }
      gate_copy.image_type = type

      if (gate.x2 < gate.y2) {
        if(type === 2){
          gate_copy.y1 = gate_copy.y1 - gateOffset
        }else if(type === 0){
          gate_copy.y1 = gate_copy.y1 + gateOffset
        }
        gate_copy.image = imagesX[type]
      } else {
        if(type === 2){
          gate_copy.x1 = gate_copy.x1 - gateOffset
        }else if(type === 0){
          gate_copy.x1 = gate_copy.x1 + gateOffset
        }
        gate_copy.image = imagesY[type]
      }

      const gates = gateElements.filter(item => {
        if (item.x1 === gate.x1 && item.y1 === gate.y1) {
          return false
        }
        return true
      })

      gates.push(gate_copy)

      setGateElements(gates)
    }
  }
  const handleUndo = () => {
    if (elementsHistory.length > 0) {
      const previousElements = elementsHistory[elementsHistory.length - 1];
      setElementsHistory(elementsHistory.slice(0, -1));
      setElements(previousElements)
    }else{
      setElements([])
    }

    if (postElementsHistory.length > 0) {
      const previousPostElements = postElementsHistory[postElementsHistory.length - 1];
      setPostElementsHistory(postElementsHistory.slice(0, -1));
      setPostElements(previousPostElements)
    }else{
      setPostElements([])
    }

    if (cellHistory.length > 0) {
      const previousCell = cellHistory[cellHistory.length - 1];
      setCellHistory(cellHistory.slice(0, -1));
      setCell(previousCell)
    }else{
      setCell(initialCell)
    }

    if (itemListHistory.length > 0) {
      const previousItemList = itemListHistory[itemListHistory.length - 1];
      setItemListHistory(itemListHistory.slice(0, -1));
      setItemList(previousItemList)
    }else{
      setItemList(initialItemList)
    }

    if (strokeHistory.length > 0) {
      const previousStroke = strokeHistory[strokeHistory.length - 1];
      setStrokeHistory(strokeHistory.slice(0, -1));
      setStroke(previousStroke)
    }else{
      setStroke(initialStroke)
    }

    if (postImageSizeHistory.length > 0) {
      const previousImageSizeHistory = postImageSizeHistory[postImageSizeHistory.length - 1];
      setPostImageSizeHistory(postImageSizeHistory.slice(0, -1));
      setPostImageSize(previousImageSizeHistory)
    }else{
      setPostImageSize(initialStroke)
    }

    if(areArraysEmpty(elementsHistory) && areArraysEmpty(postElementsHistory)){
      setShowUndo(false)
    }

    setInputLength(false)
    setLastDistance(null)
    setSecondClick(true)
    setInputString('')
  }

  return (
    <section className="px-2 pt-5 md:pb-2">
      <div className="container mx-auto">
      <PageHeading value="Build It Yourself!" />
      <div className="flex flex-col lg:flex-row w-full lg:items-end lg:justify-between">
        <div className="flex">
          <ActionButton action={action} handleAction={handleAction} />
          <div className="h-7 md:h-9 lg:mt-auto">
            <SmallButton text="Clear Grid" classes="mr-1" show={!postElements.length > 0 && !elements.length > 0 && !gateElements.length > 0 && cell === initialCell ? false : true} buttonEvent={clearCanvas} canvas={canvas} />
            <SmallButton text="Undo" classes="mr-1" show={showUndo} buttonEvent={handleUndo} />
            <Paragraph value="Top down view // 1m" classes="text-sm md:text-base absolute text-secondary-dark z-20" id="meterView" />
            <div className="absolute flex" id="zoomContainer">
              <button type="button" className="mr-2 outline-none">
                <img src={plus_2} onMouseOver={handleZoomButtonHover} onMouseOut={handleZoomButtonMouseOut} onClick={handleZoomButtonClick} id="plusButton" className="transition" alt="plus button" />
              </button>
              <button type="button" className="outline-none">
                <img src={minus_2} onMouseOver={handleZoomButtonHover} onMouseOut={handleZoomButtonMouseOut} onClick={handleZoomButtonClick} id="minusButton" className="transition" alt="minus button" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex mt-3 lg:mt-0 space-x-2 sm:space-x-4 lg:space-x-10">
            <div className="flex items-center text-xs sm:text-sm">
              <span className="block h-2 w-3 sm:w-5 bg-primary rounded-xl mr-2"></span>
              Fixed Base
            </div>
            <div className="flex items-center text-xs sm:text-sm">
              <span className="block h-2 w-3 sm:w-5 bg-green rounded-xl mr-2"></span>
              Bolt In Spigot
            </div>
            <div className="flex items-center text-xs sm:text-sm">
              <span className="block h-2 w-3 sm:w-5 bg-red rounded-xl mr-2"></span>
              Concrete-in Spigot
            </div>
            <div className="flex items-center text-xs sm:text-sm">
              <span className="block h-2 w-3 sm:w-5 bg-yellow rounded-xl mr-2"></span>
              Bracket-Only
            </div>
          </div>
        
      </div>
      <Paragraph value="Please drag the span that you want to adjust." classes="absolute text-red-300 z-20 mt-3 text-xs" id="cantAdjustText" show={!showCantAdjustText ? false : true} />
      </div>
      <section className="flex flex-row flex-wrap w-full my-4 space-y-2 lg:flex-nowrap lg:space-x-4 lg:space-y-0 lg:my-4 lg:mb-8">
        <section id="canvasContainer" className='w-full p-3 bg-gray min-h-67vh md:min-h-canvasDesktop'>
          <Canvas
            id="canvasContainer"
            boardWidth={boardWidth}
            boardHeight={boardHeight}
            handleMouseDown={handleMouseDown}
            handleMouseUp={handleMouseUp}
            handleMouseMove={handleMouseMove}
            handleDoubleClick={handleDoubleClick}
          />
          <SmallTextField classes="absolute z-20 appearance-none number-input" type="number" inputmode="numeric" placeholder="Adjust distance eg. 8460mm" show={!inputLength ? false : true} handleChange={handleInput} id="inputField" handleBlur={handleBlur} handleKeyDown={handleKeyDown} value={manualInputValue ? manualInputValue : inputString}/>
          <SmallButton classes="absolute z-20 rounded-none h-9" text="Ok" id="inputFieldBtn" buttonEvent={handleInputFieldBlur} show={!inputLength ? false : true} />
        </section>
        
      </section>
      {/* <ItemList classes="lg:w-1/3 w-full pb-28" showCheckout={showCheckOut} itemList={itemList} products={products} checkOutButtonEvent={toggleModal} /> */}
      <div className="w-full text-center mb-3">
        <PrimaryButton text="Check out" buttonEvent={toggleModal} show={showCheckOut && products} />
      </div>
      <Modal title="Save Your Plan" body={modalBody} show={clickedModal === 'checkoutModal'} closeModal={closeModal} />
      <GateAction show={gateOnQueue === null ? false : true} gate={gateOnQueue} canvas={canvas} currentEvent={gateCurrentEvent} handleRemoveGate={handleRemoveGate} handleFlipGate={handleFlipGate} />
    </section>
  );
}

export default App;
